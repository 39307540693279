import * as api from "../../apis";
import { GET_TIME_SLOT, REMOVE_TIME_SLOTS } from "../../constants/actionTypes";

export const getTimeSlots = (item, date, branch, uom) => async (dispatch) => {
  try {
    const { data } = await api.fetchTimeSlots(item, date, branch, uom);
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: GET_TIME_SLOT, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const removeTimeSlots = () => async (dispatch) => {
  try {
    // const { data } = await api.fetchTimeSlots(item, date, branch, uom)
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: REMOVE_TIME_SLOTS });
  } catch (error) {
    console.log(error);
  }
};
