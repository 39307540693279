import * as api from "../../apis";
import { CHANGE_PASSWORD, LOGIN, SIGNUP } from "../../constants/actionTypes";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

export const changePasswordRequest =
  (inputFields, setLoading, navigate, t) => async (dispatch) => {
    
    setLoading(true);
    try {
      const { data } = await api.fetchChangePassword(
        inputFields?.old_password,
        inputFields?.new_password
      );
      console.log(data);
      dispatch({ type: CHANGE_PASSWORD, payload: data });
      setLoading(false);
      toast.success(`${t("Password Updated Successfully")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      Cookies.remove("sid");
      window.location.replace(`/`);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toast.error(`${t("Passwords does not matches")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

export const signUpRequest = (formData) => async (dispatch) => {
  try {
  } catch (error) {}
};
