import * as api from "../../apis";
import { FAVOURITE_VENTURE, NATIONALITY } from "../../constants/actionTypes";

export const getNationalityList = () => async (dispatch) => {
  try {
    const { data } = await api.fetchNationalityList();

    // setInputFields(data?.message?.customer_data)
    dispatch({ type: NATIONALITY, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
