import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../store/actions/authActions/authActions";
import { changePasswordRequest } from "../../store/actions/changePasswordAction/changePasswordAction";
import "../../styles/common.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/loader/Loader";
import "./changePassword.css"

const ChangePassword = () => {
  const Language = useSelector((state) => state?.languageReducer?.language);
  const { t } = useTranslation(["main"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState();
  const handleInputChange = (e) => {
    setInputFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  console.log(inputFields);
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(changePasswordRequest(inputFields, setLoading, t));
  };
  return (
    <div className="d-flex justify-content-center profile_wrapper">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {!loading ? (
        <div className=" ">
          <h2 className="profile_heading">{t("Change Password")}</h2>
          <Form className={Language === "ar" && "changePassword"} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="profile_title">
                {t("Current Password")}
              </Form.Label>
              <Form.Control
                name="old_password"
                className="profile_input"
                onChange={(e) => handleInputChange(e)}
                type="password"
                placeholder={t("Write old Password")}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="profile_title">
                {t("Set New Password")}
              </Form.Label>
              <Form.Control
                name="new_password"
                className="profile_input"
                onChange={(e) => handleInputChange(e)}
                type="password"
                placeholder={t("Set New Password")}
              />
            </Form.Group>
            <div className="text-center">
              <Button
                className="profile_button w-100 mt-4 mb-4"
                variant="primary"
                type="submit"
                disabled={loading ? true : false}
              >
                {loading ? `${t("Updating")}...` : `${t("Update")}`}
              </Button>
            </div>
            {/* <p className="profile_title text-center">
            Don't have an account?{" "}
            <a style={{ textDecoration: "none" }} href="/signup">
              Sign up here
            </a>
          </p> */}
          </Form>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ChangePassword;
