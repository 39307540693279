import { CLEAR_USER, USER } from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { user: [] }, action) => {
  switch (action.type) {
    case USER:
      return { ...state, user: action.payload };
    case CLEAR_USER:
      return { user: [] };

    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
