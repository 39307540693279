import * as api from "../../apis";
import { GET_CART_INFO } from "../../constants/actionTypes";

export const getCartInfo = () => async (dispatch) => {
  try {
    const { data } = await api.fetchCartInfo();
    console.log(data);

    dispatch({ type: GET_CART_INFO, payload: data });
  } catch (error) {
    console.log(error);
  }
};
