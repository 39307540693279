import React from "react";
import "../../styles/common.css";
import noProfile from "../../assets/images/noProfile.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProfileSideBar = () => {
    const { t } = useTranslation(["main"]);
    const navigate = useNavigate();
    const data = useSelector((state) => state?.userReducer?.user?.message);
    const Language = useSelector((state) => state?.languageReducer?.language);

    let style = "d-flex align-items-center flex-column";
    if(Language !== "en"){
        style = style + " rtl";
    }

    return (
        <div className={style}>
            <h2
                style={{ fontSize: "22px" }}
                className="profile_heading mt-2 mb-2"
            >
                {t("Hi")}! {data?.customer_data?.customer_name === "GUEST -" ? `${t("Guest")}` : data?.customer_data?.customer_name }{" "}
            </h2>

            <div className="profile_img">
                <img
                    src={
                        data?.user_image
                            ? `https://vimtrialtemp.etoserp.com${data?.user_image}`
                            : noProfile
                    }
                    alt=""
                />
            </div>
            <h2 className="profile_title mt-2 mb-2">
                {t("Member Since")}:{" "}
                {data?.customer_data?.creation?.slice(0, 10)}{" "}
            </h2>
            <h2 className="profile_title mt-2 mb-2">
                {t("Name")}:{" "}
                {data?.customer_data?.first_name === "Guest" ? `${t("Guest")}` : data?.customer_data?.customer_name }{" "}
            </h2>
            <h2
                className="profile_title mt-2 mb-2"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/salesinvoice`)}
            >
                {t("Orders")}
            </h2>
            <h2
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/myprofile`)}
                className="profile_title mt-2 mb-2"
            >
                {t("My Profile")}
            </h2>
            <h2
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/changepassword`)}
                className="profile_title mt-2 mb-2"
            >
                {t("Change Password")}
            </h2>
        </div>
    );
};

export default ProfileSideBar;
