import {
  PLACE_ORDER,
  PLACE_ORDER_DETAILS,
  REMOVE_PLACE_ORDER_DETAILS,
} from "../../constants/actionTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { orderDetails: [] }, action) => {
  switch (action.type) {
    case PLACE_ORDER_DETAILS:
      return { ...state, orderDetails: action.payload };
    case REMOVE_PLACE_ORDER_DETAILS:
      return {
        orderDetails: [],
      };

    default:
      return state;
  }
};
