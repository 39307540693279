import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getsalesDetails } from "../../store/actions/salesAction/salesAction";
import { useTranslation } from "react-i18next";

const SalesDetails = () => {
  const { t } = useTranslation(["main"]);
  const Language = useSelector((state) => state?.languageReducer?.language);
  const params = useParams();
  const dispatch = useDispatch();
  console.log(params?.id);

  const sales = useSelector((state) => state?.salesReducer?.sales?.message);
  console.log(sales);

  useEffect(() => {
    dispatch(getsalesDetails(params?.id));
  }, []);

  return (
    <div className="mt-4 text-center">
      <div className="">
        <h3>{Language === "ar" ? "" : <span>{t("Company")}:</span>} <span>{sales?.company}</span> {Language === "ar" ? <span>:{t("Company")}</span> : ""}</h3>
        <h3>{Language === "ar" ? "" : <span>{t("Name")}:</span>}  <span>{sales?.name}</span> {Language === "ar" ? <span>:{t("Name")}</span> : ""}</h3>
        <h3>{Language === "ar" ? "" : <span>{t("Customer Name")}:</span>}  <span>{sales?.customer_name}</span> {Language === "ar" ? <span>:{t("Name")}</span> : ""}</h3>
        <h3>{Language === "ar" ? "" : <span>{t("Event name")}:</span>} <span>{sales?.event_name}</span> {Language === "ar" ? <span>:{t("Event name")}</span> : ""}</h3>
        <h3>{Language === "ar" ? "" : <span>{t("Total")}:</span>}  <span>{sales?.grand_total}</span> {Language === "ar" ? <span>:{t("Total")}</span> : ""}</h3>
        <h3>{Language === "ar" ? "" : <span>{t("Payment Status")}:</span>}  <span style={{fontWeight: "bold", color: (sales?.payment_status === "Paid" ?"green":"orangered")}}>{sales?.payment_status ? sales?.payment_status : "Pending"}</span> {Language === "ar" ? <span>:{t("Payment Status")}</span> : ""}</h3>
        {(sales?.payment_status === "Paid")?
        (
          <>
              <h3>{Language === "ar" ? "" : <span>{t("Paid Amount")}:</span>}  <span style={{fontWeight: "bold", color: "green"}} >{sales?.advance_amount} SAR</span> {Language === "ar" ? <span>:{t("Paid Amount")}</span> : ""}</h3>
              <h3>{Language === "ar" ? "" : <span>{t("Transaction ID")}:</span>}  <span style={{fontWeight: "bold"}} >{sales?.payment_invoice_id}</span> {Language === "ar" ? <span>:{t("Transaction ID")}</span> : ""}</h3>
          </>
        )
        :(
          null
        )}
        <h3
          dangerouslySetInnerHTML={{
            __html: sales?.company_address_display,
          }}
        ></h3>
      </div>
    </div >
  );
};

export default SalesDetails;
