import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form } from "react-bootstrap";
import "./FoodCard.css";
import pizza from "../../assets/images/pizza.jpg";
import { fetchUpdateAddons } from "../../store/apis";
import { useDispatch, useSelector } from "react-redux";
import { getCartInfo } from "../../store/actions/cartInfoAction/cartInfoAction";
import debounce from "lodash.debounce";
import { useDebounce } from "../../hooks/useDebounce";

const FoodCard = ({ data, setUpdate, index }) => {
  console.log(data);
  const dispatch = useDispatch();
  const [update1, setUpdate1] = useState(false);
  const [type, setType] = useState("");

  const cart = useSelector((state) => state?.cartInfoReducer?.cart?.message);

  const cartQty = cart?.items?.filter((item, index) => {
    return item?.item_code === data?.item_code;
  });
  console.log(cartQty);

  const [inputFields, setInputFields] = useState({
    id: "",
    qty: 0,
    item_code: "",
  });
  console.log(inputFields);

  const updateCart = async (id, item_code, qty) => {
    if (inputFields?.qty || inputFields?.qty === 0) {
      const qty = document.getElementById(inputFields?.id);
      console.log(qty?.value);
      debugger;

      try {
        debugger;
        const res = await fetchUpdateAddons(data?.item_code, qty?.value);
        console.log(res);
        setUpdate(true);
        setUpdate1(false);
      } catch {}
    }
  };

  useEffect(() => {
    if ((inputFields?.qty || inputFields?.qty == 0) && update1) {
      setTimeout(function () {
        updateCart();
      }, 1000);
    }
    if (type === "REMOVE") {
      setTimeout(function () {
        updateCart();
      }, 1000);
    }
  }, [inputFields]);

  // const debouncedResults = useMemo(() => {
  //   if (inputFields?.qty) {
  //     // return debounce(updateCart, 600);
  //   }
  // }, [inputFields?.qty]);

  // const debouncedResults = useMemo(() => {
  //   if (update1) {
  //     return debounce(updateCart, 500);
  //   }
  // }, [inputFields?.qty, update1]);

  // const debouncedResults = useMemo(() => {
  //   debugger;
  //   if (inputFields?.qty || inputFields?.qty === 0) {
  //     // setUpdate1(true);
  //     return debounce(updateCart, 2000);
  //   }
  // }, [inputFields?.qty]);

  useEffect(() => {
    if (data) {
      setInputFields({
        item_code: data?.item_code,
        qty: cartQty?.length > 0 ? cartQty[0]?.qty : 0,
        id: "",
      });
    }
  }, [data]);

  const addGuest = (e) => {
    debugger;
    setInputFields({
      item_code: data?.item_code,
      qty: Number(inputFields?.qty) + 1,
      id: e.target.name,
    });
    // debouncedResults();
    setUpdate1(true);
  };
  const removeGuest = (e) => {
    if (Number(inputFields?.qty) > 0) {
      setInputFields((prev) => {
        return {
          ...prev,
          qty: Number(inputFields?.qty) - 1,
          id: e.target.name,
        };
      });
    }
    // debouncedResults();
    setUpdate1(true);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    console.log(e.target.name);
    setInputFields((prev) => {
      return { ...prev, qty: e.target.value, id: e.target.name };
    });
    // debouncedResults();
    setUpdate1(true);
  };

  return (
    <Col className="foodCard_cardDiv" md lg="3">
      <Card className="foodCard_cardContainer">
        <div className="d-flex justify-content-center foodCard_cardImg">
          <Card.Img className="" variant="top" src={`https://${data?.image}`} />
        </div>
        <Card.Body className="d-flex justify-content-center flex-column align-items-center">
          <div className="foodcard_title">{data?.item_name.slice(0, 25)}</div>
          <div className="foodcard_title">{data?.formatted_price}</div>

          <div className="d-flex">
            <Button
              className="pageone_addButton"
              name={data?.id}
              onClick={removeGuest}
            >
              -
            </Button>
            <div className="count_div text-center">
              {" "}
              <Form.Control
                required
                id={data?.id}
                value={inputFields?.qty ? inputFields?.qty : 0}
                className="foodcard_input"
                type="text"
                name={data?.id}
                onChange={(e) => handleInputChange(e)}
                placeholder=" number"
              />
            </div>
            <Button
              className="pageone_addButton"
              name={data?.id}
              onClick={addGuest}
            >
              +
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FoodCard;
