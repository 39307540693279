import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ProfileSideBar from "../../components/profilesidebar/ProfileSideBar";
import { getCharacterList } from "../../store/actions/characterListAction/characterListAction";
import { getColorList } from "../../store/actions/colorAction/colorAction";
import { getUserData } from "../../store/actions/userData/userDataAction";
import { updateUserData } from "../../store/apis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/common.css";
import { getRealtionList } from "../../store/actions/realtionAction/realtionAction";
import { useTranslation } from "react-i18next";


const EditAdult = () => {
  const Language = useSelector((state) => state?.languageReducer?.language);
  const params = useParams();
  const itemId = params?.id;
  console.log(itemId);
  const { t } = useTranslation(["main"]);
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editChild, setEditChild] = useState();
  const [inputFields, setInputFields] = useState({
    customer_family_detail: [],
  });
  console.log(inputFields);

  const dispatch = useDispatch();

  const AllDataList = useSelector(
    (state) => state?.userReducer?.user?.message?.customer_data
  );
  const data = useSelector(
    (state) =>
      state?.userReducer?.user?.message?.customer_data?.customer_family_detail
  );
  console.log(data);
  const filterData = data?.filter((item, index) => {
    return item.adult === 1;
  });

  const realtion = useSelector(
    (state) => state?.realtionReducer?.realtion?.message
  );

  useEffect(() => {
    setEditChild(
      filterData?.filter((item, index) => item?.idx === Number(itemId))[0]
    );
  }, [data]);

  console.log(editChild);

  useEffect(() => {
    dispatch(getUserData(setInputFields));
    dispatch(getRealtionList());
    // dispatch(getCharacterList());
    // dispatch(getColorList());
  }, []);

  useEffect(() => {
    if (update) {
      dispatch(getUserData(setInputFields));
    }
  }, [update]);
  useEffect(() => {
    // dispatch(updateUserData(inputFields));

    console.log(inputFields);
    if (editable) {
      updateFamilyData();
    }
  }, [inputFields?.customer_family_detail, editable]);

  const updateFamilyData = async () => {
    try {
      const res = await updateUserData(inputFields);

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditChild((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let familiyData = inputFields?.customer_family_detail;
  //   let mydata = familiyData?.concat(adult);
  //   setInputFields((prev) => {
  //     return {
  //       ...prev,
  //       customer_family_detail: mydata,
  //     };
  //   });
  //   setEditable(true);
  // };
  console.log(AllDataList);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // dispatch(updateChildList(editChild));
    const allData = AllDataList.customer_family_detail?.map((i, k) => {
      if (i.idx === Number(itemId)) {
        return Object.assign(i, { ...editChild });
      }
      return i;
    });

    const all = Object.assign(AllDataList, { customer_family_detail: allData });
    var regex = new RegExp(
      /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
    );

    try {
      const res = await updateUserData(all);

      if (res?.data.message?.error === true) {
        toast.error(
          `${t("Mobile Number should be of length 10, consists of only numbers 0-9 and start with 0")}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } else {
        toast.success(`${t("Updated Successfully")}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("myprofile");
      }
      toast.success(`${t("Updated Successfully")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdate(!update);
      navigate("/addadult");
    } catch (error) {
      toast.error(`${t("Something is wrong")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div className="profile_wrapper">
      <Container>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row className={`${Language === "ar" && "flex-row-reverse"}`}>
          <Col md lg="4">
            <div>
              <ProfileSideBar />
            </div>
          </Col>
          <Col md lg="8">
            <div>
              <h3 className="profile_heading">{t("Edit Adult")}</h3>
              <div>
                <Form className={Language === "ar" && "addadults"} onSubmit={(e) => handleSubmit(e)}>
                  <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <Form.Group
                      className="mb-3 profile_col45"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span>{t("First Name")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        className={`profile_input ${Language === "ar" && "alignright"}`}
                        type="text"
                        name="first_name"
                        defaultValue={editChild?.first_name}
                        onChange={(e) => handleInputChange(e)}
                        placeholder={t("First Name")}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col45"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span> {t("Last Name")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        className={`profile_input ${Language === "ar" && "alignright"}`}
                        type="text"
                        name="last_name"
                        defaultValue={editChild?.last_name}
                        onChange={(e) => handleInputChange(e)}
                        placeholder={t("Last Name")}
                      />
                    </Form.Group>
                  </div>
                  <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <Form.Group
                      className="mb-3 profile_col45"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span> {t("Phone No.")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="phone_no"
                        className={`profile_input ${Language === "ar" && "alignright"}`}
                        minLength={10}
                        maxLength={10}
                        min={0}
                        type="text"
                        defaultValue={editChild?.phone_no}
                        placeholder={t("number")}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col45"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span> {t("Email Id")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        className={`profile_input ${Language === "ar" && "alignright"}`}
                        type="email"
                        name="email_id"
                        onChange={(e) => handleInputChange(e)}
                        defaultValue={editChild?.email_id}
                        placeholder={t("Enter email")}
                      />
                    </Form.Group>
                  </div>

                  <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span>{t("Date of Birth")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        className="profile_input"
                        type="date"
                        name="dob"
                        max={"9999-12-31"}
                        value={editChild?.dob || ""}
                        onChange={(e) => handleInputChange(e)}
                        placeholder={t("person name")}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span> {t("Preferred Communication")}</span>{" "}
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Select
                        required
                        className="profile_input"
                        name="preferred_communication"
                        aria-label="Default select example"
                        value={editChild?.preferred_communication || ""}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">
                          {" "}
                          {t("Select Preferred Communication")}
                        </option>
                        <option value="Both (SMS or Email)">
                          {t("Both (SMS or Email)")}
                        </option>
                        <option value="Email">{t("Email")}</option>
                        <option value="SMS">{t("SMS")}</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span> {t("Relation")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => handleInputChange(e)}
                        className="profile_input"
                        name="relation"
                        value={editChild?.relation || ""}
                        aria-label="Default select example"
                        required
                      >
                        <option value="">{t("Select Relation")}</option>
                        {realtion?.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <Button
                    className={`profile_button ${Language === "ar" && "d-flex"}`}
                    variant="primary"
                    type="submit"
                  >
                    {t("Update")}
                  </Button>

                </Form>
              </div>

              <div className={`d-flex justify-content-center ${Language === "ar" && "flex-row-reverse"}`}>
                <Button
                  onClick={() => {
                    navigate(`/myprofile`);
                  }}

                  className={`profile_button  ${Language === "ar" ? "btnArMargin" : "me-4"}`}
                >
                  {t("My Profile")}
                </Button>
                <Button
                  className="profile_button"
                  onClick={() => {
                    navigate(`/addadult`);
                  }}
                >
                  {t("Add Additional Adult")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditAdult;
