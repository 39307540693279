import * as api from "../../apis";
import { FAVOURITE_VENTURE, GET_ITEM_LIST } from "../../constants/actionTypes";

export const getItemList = (start, per) => async (dispatch) => {
  try {
    const { data } = await api.fetchItemList(start, per);
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: GET_ITEM_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
