import { GET_ADDONS } from "../../constants/actionTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { addons: [] }, action) => {
  switch (action.type) {
    case GET_ADDONS:
      return { ...state, addons: action.payload };

    default:
      return state;
  }
};
