import { SALES_ORDER_DETAILS } from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { sales: [] }, action) => {
  switch (action.type) {
    case SALES_ORDER_DETAILS:
      return { ...state, sales: action.payload };

    default:
      return state;
  }
};
