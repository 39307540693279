import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileSideBar from "../../components/profilesidebar/ProfileSideBar";
import { getCharacterList } from "../../store/actions/characterListAction/characterListAction";
import { getColorList } from "../../store/actions/colorAction/colorAction";
import { getUserData } from "../../store/actions/userData/userDataAction";
import { updateUserData } from "../../store/apis";
import edit from "../../assets/images/create.png";
import delete1 from "../../assets/images/delete.png";
import "../../styles/common.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import "./AddChild.css";

const AddChild = () => {
  const Language = useSelector((state) => state?.languageReducer?.language);
  const { t } = useTranslation(["main"]);
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [editable, setEditable] = useState(false);
  const [inputFields, setInputFields] = useState({
    customer_family_detail: [],
  });
  console.log(inputFields);
  const [adult, setAdult] = useState({
    adult: 0,
    child: 1,
  });
  console.log(adult);
  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state?.userReducer?.user?.message?.customer_data
  );
  const filterData = data?.customer_family_detail?.filter((item, index) => {
    return item.child === 1;
  });
  console.log(filterData);
  useEffect(() => {
    dispatch(getUserData(setInputFields));
    dispatch(getCharacterList());
    dispatch(getColorList());
  }, []);

  const character = useSelector(
    (state) => state?.characterReducer?.character?.message
  );
  const color = useSelector((state) => state?.colorReducer?.color?.message);

  const redirectEdit = (i) => {
    navigate(`/editchild/${i}`);
  };

  const deleteChild = (p) => {
    setDeleteItem(
      filterData?.filter((item, index) => item?.idx === Number(p))[0]
    );
    const allData = data?.customer_family_detail?.filter((i, k) => {
      if (i.idx !== Number(p)) {
        return i;
      }
    });

    const all = Object.assign(data, { customer_family_detail: allData });
    setLoading1(true);
    try {
      updateUserData(all);
      toast.error(`${t("Deleted Successfully")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading1(false);
    } catch (error) {
      console.log(error);
      setLoading1(false);
    }
  };

  useEffect(() => {
    if (update) {
      dispatch(getUserData(setInputFields));
    }
  }, [update]);
  useEffect(() => {
    // dispatch(updateUserData(inputFields));
    console.log(inputFields);
    if (editable) {
      updateFamilyData();
    }
  }, [inputFields?.customer_family_detail, editable]);
  console.log(adult);
  const updateFamilyData = async () => {
    if (adult?.gender.length > 0) {
      setLoading(true);
      try {
        const res = await updateUserData(inputFields);
        setUpdate(true);
        toast.success(
          `${res?.data?.message?.coupon_code
            ? `Your coupon code is ${res?.data?.message?.coupon_code},You can use it within 30days`
            : `${t("Added Successfully")}`
          }`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        // setInputFields({
        //   customer_family_detail: [],
        // });
        setAdult({
          adult: 0,
          child: 1,
        });
        console.log(res);
        setEditable(false);
        setLoading(false);
      } catch (error) {
        toast.error(`${t("Something is wrong")}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAdult({
          adult: 0,
          child: 1,
        });
        console.log(error);
        setLoading(false);
      }
    } else {
      toast.error(`${t("Add Gender")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleInputChange = (e) => {
    setAdult((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  console.log(adult);
  const handleSubmit = (e) => {
    e.preventDefault();
    let familiyData = inputFields?.customer_family_detail;
    let mydata = familiyData?.concat(adult);
    setInputFields((prev) => {
      return {
        ...prev,
        customer_family_detail: mydata,
      };
    });
    setUpdate(false);
    setEditable(true);
  };
  return (
    <div className="profile_wrapper addChild">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Container>
        <Row className={`${Language === "ar" && "flex-row-reverse"}`}>
          <Col md lg="4">
            <div>
              <ProfileSideBar />
            </div>
          </Col>
          <Col md lg="8">
            <div>
              {!loading ? (
                <div>
                  <h3 className="profile_heading">{t("Add Child")}</h3>
                  <div>
                    <Form className={Language === "ar" && "addChildAr"} onSubmit={(e) => handleSubmit(e)}>
                      <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                        <Form.Group
                          className="mb-3 profile_col20"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span> {t("Gender")}{" "}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Select
                            className="profile_input"
                            name="gender"
                            aria-label="Default select example"
                            required
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option
                              selected={
                                adult?.gender?.length > 0 ? false : true
                              }
                              value=""
                            >
                              {t("Select Gender")}
                            </option>
                            <option value="Male">{t("Boy")}</option>
                            <option value="Female">{t("Girl")}</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col30"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span> {t("Child Name")}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            required
                            className={`profile_input ${Language === "ar" && "alignright"}`}
                            type="text"
                            name="child_name"
                            value={adult?.child_name || ""}
                            onChange={(e) => handleInputChange(e)}
                            placeholder={t("Child Name")}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col30"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="profile_title">
                            {t("School Name")}
                          </Form.Label>
                          <Form.Control
                            className={`profile_input ${Language === "ar" && "alignright"}`}
                            type="text"
                            name="school_name"
                            value={adult?.school_name || ""}
                            onChange={(e) => handleInputChange(e)}
                            placeholder={t("School Name")}
                          />
                        </Form.Group>
                      </div>
                      <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                        <Form.Group
                          className="mb-3 profile_col20"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="profile_title">
                            {t("Favorite Character")}
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => handleInputChange(e)}
                            className="profile_input"
                            name="favorite_character"
                            value={adult?.favorite_character || ""}
                            aria-label="Default select example"
                          >
                            <option
                              selected={
                                adult?.favorite_character?.length > 0
                                  ? false
                                  : true
                              }
                              value=""
                            >
                              {t("Select Favorite Character")}
                            </option>
                            {character?.map((item, index) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col30"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="profile_title">
                            {t("Favorite Color")}
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => handleInputChange(e)}
                            className="profile_input"
                            name="favourite_colour"
                            aria-label="Default select example"
                            value={adult?.favourite_colour || ""}
                          >
                            <option
                              selected={
                                adult?.favourite_colour?.length > 0
                                  ? false
                                  : true
                              }
                              value=""
                            >
                              {t("Select Favorite Color")}
                            </option>
                            {color?.map((item, index) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col30"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                          <span>{t("Date of Birth")}{" "}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            required
                            className="profile_input"
                            type="date"
                            name="dob"
                            max={"9999-12-31"}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Enter date of birth"
                          />
                        </Form.Group>
                      </div>

                      <Button
                        className={`profile_button  mt-4 mb-4 ${Language === "ar" && "d-flex"}`}
                        variant="primary"
                        type="submit"
                      >
                        {t("Add")}
                      </Button>
                    </Form>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
              {!loading1 ? (
                <div>
                  <div>
                    <Table dir={Language === "ar" ? "rtl" : "ltr"} striped>
                      <thead>
                        <tr className="profile_title">
                          <th>{t("sr")}</th>
                          <th>{t("Gender")}</th>
                          <th>{t("Name")}</th>
                          <th>{t("School Name")}</th>
                          <th>{t("Favorite Character")}</th>
                          <th>{t("Favorite Color")}</th>
                          <th width="100px">{t("DOB")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterData?.map((item, index) => {
                          return (
                            <tr className="profile_title2">
                              <td>{index + 1}</td>
                              <td>
                                {item?.gender === "Female" ? "Girl" : "Boy"}
                              </td>
                              <td>{item?.child_name}</td>
                              <td>{item?.school_name}</td>
                              <td>{item?.favorite_character}</td>
                              <td>{item?.favourite_colour}</td>

                              <td>{item?.dob}</td>
                              <td>
                                <div className="d-flex">
                                  <div
                                    className="me-2"
                                    onClick={() => redirectEdit(item?.idx)}
                                  >
                                    <img src={edit} alt="" />
                                  </div>
                                  <div
                                    variant="danger"
                                    onClick={() => deleteChild(item?.idx)}
                                  >
                                    <img src={delete1} alt="" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div className={`d-flex py-2 justify-content-center ${Language === "ar" && "flex-row-reverse"}`}>
                    <Button
                      onClick={() => {
                        navigate(`/myprofile`);
                      }}
                      style={{
                        width: "200px",
                      }}
                      className={`profile_button ${Language === "ar" ? "btnArMargin" : "me-4"}`}
                    >
                      {t("My Profile")}
                    </Button>
                    <Button
                      className="profile_button"
                      style={{
                        width: "200px",
                      }}
                      onClick={() => {
                        navigate(`/addadult`);
                      }}
                    >
                      {t("Add Additional Adult")}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center loaderClass">
                  <Loader />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddChild;
