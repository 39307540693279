import * as api from "../../apis";
import { GET_BRAND_LIST } from "../../constants/actionTypes";

export const getBrandList = () => async (dispatch) => {
  try {
    const { data } = await api.fetchBrandList();
    console.log(data);

    dispatch({ type: GET_BRAND_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
