import * as api from "../../apis";
import { LOGIN, SIGNUP } from "../../constants/actionTypes";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClearUserData } from "../userData/userDataAction";

export const loginRequest =

  (inputFields, navigate, setLoading, t) => async (dispatch) => {

    setLoading(true);

    try {
      const { data } = await api.fetchLogin(
        inputFields?.number,
        inputFields?.password
      );

      dispatch({ type: LOGIN, payload: data });

      Cookies.set("sid", data?.full_name);

      setLoading(false);
      toast.success(`${t("Logged In Successfully")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(ClearUserData());
      window.location.replace(`/myprofile`);
    } catch (error) {
      console.log(error);
      toast.error(`${t("Invalid login credentials")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

export const signUpRequest = (formData) => async (dispatch) => {
  try {
  } catch (error) { }
};
