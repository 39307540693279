import { COUPON_CODE, GET_CART_INFO } from "../../constants/actionTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { cart: [] }, action) => {
  switch (action.type) {
    case GET_CART_INFO:
      console.log(state?.cart);
      return { ...state, cart: action.payload };

    default:
      return state;
  }
};
