import * as api from "../../apis";
import { ToastContainer, toast } from "react-toastify";

export const forgotPassword =
  (email, navigate, setLoading) => async (dispatch) => {
    setLoading(true);
    try {
      const { data } = await api.fetchForgotPassword(email);
      console.log(data);
      toast.success(data?.message?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      //   navigate("/");
      // setInputFields(data?.message?.customer_data)
      // dispatch({ type: GET_REALTION_LIST, payload: data });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
