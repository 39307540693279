import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Funtazia from "../../assets/images/Funtazia.png";
import "./PartyCard.css";
import { useTranslation } from "react-i18next";

const PartyCard = ({ item }) => {
  const { t } = useTranslation(["main"]);
  const navigate = useNavigate();

  const redirect = (id) => {
    navigate(`/carddetail/${id}`);
  };
  return (
    <Col className="partyCard_cardDiv" md lg="3">
      <Card className="partyCard_cardContainer">
        <div className="d-flex justify-content-center partyCard_cardImg">
          <Card.Img
            className=""
            variant="top"
            src={`https://${item?.website_image}`}
          />
        </div>
        <Card.Body className="d-flex justify-content-center flex-column align-items-center">
          <div className="partyCard_title">{item?.item_name}</div>
          <div className="partyCard_title">{item?.formatted_price}</div>
          <Button
            onClick={() => redirect(item?.item_code)}
            className="partyCard_button"
            variant="primary"
          >
            {t("Book Now")}
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default PartyCard;
