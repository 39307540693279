import React, { startTransition, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../store/actions/authActions/authActions";
import "../../styles/common.css";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { getSocialLogin } from "../../store/apis";


const Login = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [socailState, setSocailState] = useState("")
    const Language = useSelector((state) => state?.languageReducer?.language);
    const { t } = useTranslation(["main"]);
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState();
    const handleInputChange = (e) => {
        setInputFields((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            await dispatch(loginRequest(inputFields, navigate, setLoading, t))

        } catch (error) {
            console.log(error)
        }
    };


    const handleGoogleLogin = async (response) => {
        const decoded = jwt_decode(response.credential)

    }

    useEffect(() => {
        /* global google*/
        // google?.accounts?.id?.initialize({
        //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        //     callback: handleGoogleLogin,

        // })
        // google?.accounts?.id?.renderButton(
        //     document.getElementById("googleButton"),
        //     {
        //         theme: "outline",
        //         size: "large",
        //     }
        // )
    }, [])

    const loadSocialLogin = async () => {
        try {
            const res = await getSocialLogin();
            const { data } = res;
            const getGoogle = data.message.find(item => item.name === "google")
            const getStete = new URLSearchParams(getGoogle?.auth_url.substring(getGoogle?.auth_url.indexOf("?") + 1))
            setSocailState(getStete.get("state"))
        } catch (error) {
            console.log(error);
        }
    }



    useEffect(() => {
        loadSocialLogin()
    }, []);


    return (
        <div className="d-flex justify-content-center profile_wrapper">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div>
                <h2 className="profile_heading">
                    {t("Sign in to your account")}
                </h2>

                <Form
                    className={Language === "en" ? "login" : "loginar"}
                    onSubmit={handleSubmit}
                >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="profile_title">
                            {t("Mobile No.")}/{t("Email Id")}
                            <span
                                style={{
                                    color: "red",
                                    fontSize: "1.2rem",
                                }}
                            >
                                *
                            </span>
                        </Form.Label>
                        <Form.Control
                            required
                            className="profile_input"
                            name="number"
                            value={inputFields?.number || ""}
                            onChange={(e) => handleInputChange(e)}
                            type="text"
                            placeholder={t("Enter email")}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="profile_title">
                            {t("Password")}
                            <span
                                style={{
                                    color: "red",
                                    fontSize: "1.2rem",
                                }}
                            >
                                *
                            </span>
                        </Form.Label>
                        <Form.Control
                            required
                            value={inputFields?.password || ""}
                            name="password"
                            className="profile_input"
                            onChange={(e) => handleInputChange(e)}
                            type="password"
                            placeholder={t("Password")}
                        />
                    </Form.Group>
                    <div className="text-center">
                        <Button
                            disabled={loading ? true : false}
                            className="profile_button  w-100 mt-4 mb-1"
                            variant="primary"
                            type="submit"
                        >
                            {loading ? `${t("Loading")}...` : `${t("Sign In")}`}
                        </Button>
                    </div>

                    <div className="d-flex justify-content-end">
                        <a
                            style={{ textDecoration: "none" }}
                            href="/forgotpassword"
                        >
                            {t("Forgot Password")}
                        </a>
                    </div>
                    {/* <div onClik={handleGoogleLogin} className="text-center">
                        <Button
                            disabled={loading ? true : false}
                            className="profile_button googleLogin w-100 mt-4 mb-1"
                            variant="primary"


                        >
                            {loading ? `${t("Loading")}...` : <p> <img src={googleIcon} alt="google log in" /> <span> {t("Sign in with Google")}</span> </p>}
                        </Button>
                    </div> */}
                    {/* <div id="googleButton" className="text-center">
                        ?
                    </div> */}


                    <p className="profile_title text-center">
                        {t("Don't have an account?")}{" "}
                        <a style={{ textDecoration: "none" }} href="/signup">
                            {t("Sign up here")}
                        </a>
                    </p>
                </Form>
            </div >
        </div >
    );
};

export default Login;
