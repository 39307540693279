import { toast } from "react-toastify";
import * as api from "../../apis";
import { COUPON_CODE, GET_BRANCH_LIST } from "../../constants/actionTypes";
import { getCartInfo } from "../cartInfoAction/cartInfoAction";
import { useTranslation } from "react-i18next";

export const getApplyCouponCode = (code, t) => async (dispatch) => {

  try {
    const { data } = await api.fetchApplyCouponCode(code);
    console.log(data);
    if (!data?.message?.error) {
      toast.success(`${t("Coupon Applied Successfully")}`);
      dispatch(getCartInfo());
    } else {
      toast.error(`${t("Please enter valid coupon code")}`);
    }
  } catch (error) {
    console.log(error);
  }
};

