import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../store/actions/userData/userDataAction";
import { attachPhoto, updateUserData, uploadProfile } from "../../store/apis";
import "./Profile.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileSideBar from "../../components/profilesidebar/ProfileSideBar";
import { getCityList } from "../../store/actions/cityAction/cityAction";
import { getFavouriteVenture } from "../../store/actions/favouriteVentureAction/favouriteVentureAction";
import { getNationalityList } from "../../store/actions/nationalityAction/nationalityAction";
import { Loader } from "../../components/loader/Loader";
import load from "../../assets/images/loader.svg";
import { useTranslation } from "react-i18next";
import { emptyFlow } from "../../store/actions/flowAction/flowAction";

const Profile = () => {
    const Language = useSelector((state) => state?.languageReducer?.language);
    const { t } = useTranslation(["main"]);
    const navigate = useNavigate();
    const [update, setUpdate] = useState(false);
    const [defaultCity, setDefaultCity] = useState();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [dobState, setDobState] = useState();

    const [error, setError] = useState({
        name: false,
        mobile: false,
    });
    const [inputFields, setInputFields] = useState({});
    const [files, setFiles] = useState();

    const dispatch = useDispatch();

    const data = useSelector(
        (state) => state?.userReducer?.user?.message?.customer_data
    );

    const profile_percentage = useSelector(
        (state) => state?.userReducer?.user?.message?.profile_percetage
    );
    const venture = useSelector(
        (state) => state?.favouriteVentureReducer?.venture?.message
    );

    const dobValue = data?.customer_family_detail?.filter((item, index) => {
        return item.relation === "self";
    });


    const profile = useSelector((state) => state?.userReducer?.user?.message);
    const nationality = useSelector(
        (state) => state?.nationalityReducer?.nationality?.message
    );

    const city1 = useSelector((state) => state?.cityReducer?.city?.message);

    const filterChildData = data?.customer_family_detail?.filter(
        (item, index) => {
            return item.child === 1;
        }
    );
    const filterAdultData = data?.customer_family_detail?.filter(
        (item, index) => {
            return item.adult === 1;
        }
    );

    let percent = 0;
    const calculate = () => {
        if (
            data?.first_name?.length > 0 &&
            data?.last_name?.length > 0 &&
            data?.email_id?.length > 0 &&
            data?.preferred_communication?.length > 0 &&
            data?.nationality?.length > 0 &&
            data?.city?.length > 0
        ) {
            percent += 50;
        }
        // if (profile?.user_image) {
        //   percent += 25;
        // }
        if (filterAdultData?.length > 0) {
            percent += 25;
        }
        if (filterChildData?.length > 0) {
            percent += 25;
        }
    };
    calculate();
    useEffect(() => {
        dispatch(getUserData(setInputFields));
        dispatch(getCityList(setDefaultCity));
        dispatch(getFavouriteVenture());
        dispatch(getNationalityList());
        // setInputFields(data);
    }, []);

    // const updateCustomer = useCallback(() => {
    //   if (update) {
    //     dispatch(getUserData(setInputFields));
    //     setUpdate(false);
    //   }
    // }, [update]);

    // console.log(data?.email_id);
    useEffect(() => {
        if (update) {
            dispatch(getUserData(setInputFields));
            setUpdate(false);
        }
    }, [update]);
    const fileUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", files);
        setLoading1(true);
        try {
            const data1 = await uploadProfile(formData);
            if (data1?.data?.message?.file_url) {
                const res = await attachPhoto(
                    data?.email_id,
                    data1?.data?.message?.file_url
                );
                if (res?.statusText === "OK") {
                    setUpdate(true);
                }
                setFiles("");
            }

            setLoading1(false);
            toast.success("Updated Successfully", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (err) {
            console.log(err);
            setLoading1(false);
            toast.error(`${t("Format is not supported")}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setFiles("");
        }
    };
    const handleInputChange = (e) => {
        setInputFields(() => {
            return { ...inputFields, [e.target.name]: e.target.value };
        });
    };
    const handleInputChange1 = (e) => {
        e.preventDefault();
        const self = inputFields?.customer_family_detail?.map((item, index) => {
            if (item?.relation === "self") {
                item.dob = e.target.value;
            }
            return item;
        });
        setInputFields({ ...inputFields, customer_family_detail: self });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var regex = new RegExp(
            /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
        );
        setLoading(true);
        try {
            const res = await updateUserData(inputFields);

            if (res?.data.message?.error === true) {
                toast.error(
                    `${t("Mobile Number should be of length 10, consists of only numbers 0-9 and start with 0")}`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
            } else {
                toast.success("Updated Successfully", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            setUpdate(true);
            setLoading(false);
        } catch (error) {
            toast.error(`${t("Something is wrong")}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };
    useEffect(() => {
        dispatch(emptyFlow());
    }, []);
    return (
        <div className="profile_wrapper">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <Container>
                <Row className={`${Language === "ar" && "flex-row-reverse"}`}>
                    <Col md lg="4" order="2">
                        <div className="text-center ">
                            <ProfileSideBar />
                            <div className="mt-4">
                                <h4 className="mt-4 mb-4">
                                    {t("Profile Completion Progress")}
                                </h4>
                                <Progress
                                    type="circle"
                                    strokeWidth={4}
                                    percent={profile_percentage}
                                />
                                {!filterChildData?.length > 0 && (
                                    <p className="mt-3">
                                        {" "}
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "1.2rem",
                                            }}
                                        >
                                            *
                                        </span>
                                        {t("Add atleast 1 child to complete profile")}
                                    </p>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col md lg="8" order="1">
                        <div>
                            {!loading ? (
                                <div>
                                    <h3 className="profile_heading mt-2 mb-2">
                                        {t("Update profile")}
                                    </h3>
                                    <div>
                                        <Form className={Language === "ar" && "myprofile"} onSubmit={(e) => handleSubmit(e)}>
                                            <div>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="profile_title">
                                                        {t("Full Name")}{" "}
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        name="fullname"
                                                        type="text"
                                                        className={`profile_input ${Language === "ar" && "alignright"}`}
                                                        value={
                                                            data?.first_name &&
                                                                data?.last_name
                                                                ? data?.first_name +
                                                                " " +
                                                                data?.last_name
                                                                : data?.first_name
                                                                    ? data?.first_name
                                                                    : data?.last_name
                                                                        ? data?.last_name
                                                                        : null
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                        placeholder={t("Full Name")}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        <span> {t("First Name")}{" "}</span>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="first_name"
                                                        value={
                                                            inputFields?.first_name ||
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                        placeholder={t("First Name")}
                                                        className={`profile_input ${Language === "ar" && "alignright"}`}
                                                    />
                                                </Form.Group>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        <span>{t("Last Name")}{" "}</span>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        className={`profile_input ${Language === "ar" && "alignright"}`}
                                                        type="text"
                                                        name="last_name"
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                        value={
                                                            inputFields?.last_name ||
                                                            ""
                                                        }
                                                        placeholder={t("Last Name")}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        <span>{t("Nationality")}{" "}</span>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        required
                                                        defaultValue={
                                                            data?.nationality
                                                        }

                                                        className="profile_input"
                                                        name="nationality"
                                                        aria-label="Default select example"
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                    >
                                                        <option>
                                                            {data?.nationality}
                                                        </option>
                                                        {nationality?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <>
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        <span> {t("Preferred Communication")}{" "}</span>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>

                                                    <Form.Select
                                                        required
                                                        className="profile_input selectAr"
                                                        name="preferred_communication"
                                                        aria-label="Default select example"
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                    >
                                                        <option>
                                                            {" "}
                                                            {
                                                                data?.preferred_communication === "Both (SMS or Email)" ? `${t("Both (SMS or Email)")}` : data?.preferred_communication === "SMS" ? `${t("SMS")}` : `${t("Email")}`
                                                            }
                                                        </option>
                                                        <option value="Both (SMS or Email)">
                                                            {t("Both (SMS or Email)")}
                                                        </option>
                                                        <option value="Email">
                                                            {t("Email")}
                                                        </option>
                                                        <option value="SMS">
                                                            {t("SMS")}
                                                        </option>
                                                    </Form.Select>


                                                </Form.Group>
                                            </div>
                                            <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                                                <div className={`d-flex  profile_col45 ${Language === "ar" && "flex-row-reverse"}`}>
                                                    <Form.Group
                                                        className="mb-3 profile_col30"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label className="profile_title">
                                                            {t("Code")}
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="profile_input"
                                                            disabled
                                                            aria-label="Default select example"
                                                        >
                                                            <option>
                                                                +966
                                                            </option>
                                                            <option value="1">
                                                                One
                                                            </option>
                                                            <option value="2">
                                                                Two
                                                            </option>
                                                            <option value="3">
                                                                Three
                                                            </option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group
                                                        className="mb-3 profile_col70"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                            <span> {t("Mobile No.")}{" "}</span>
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize:
                                                                        "1.2rem",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            name="mobile_no"
                                                            className={`profile_input ${Language === "ar" && "alignright"}`}
                                                            minLength={10}
                                                            maxLength={10}
                                                            type="text"
                                                            defaultValue={
                                                                data?.mobile_no
                                                            }
                                                            placeholder={t("number")}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        <span> {t("Email Id")}{" "} </span>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        name="email_id"
                                                        className="profile_input"
                                                        defaultValue={
                                                            data?.email_id
                                                        }
                                                        dir={Language === "ar" ? "rtl" : "ltr"}
                                                        type="email"
                                                        placeholder={t("Enter email")}
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        <span> {t("City")}</span>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        name="city"
                                                        className="profile_input"
                                                        aria-label="Default select example"
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                    >
                                                        <option>
                                                            {data?.city ||
                                                                `${t("select city")}`}
                                                        </option>
                                                        {city1?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <>
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        <span>
                                                            {t("Channel used at registration")}{" "}
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="profile_input"
                                                        disabled
                                                        dir={Language === "ar" ? "rtl" : "ltr"}
                                                        defaultValue={"Online"}
                                                        type="text"
                                                        placeholder={t("Online")}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="profile_title">
                                                        {t("Favourite Venue")}
                                                    </Form.Label>
                                                    <Form.Select
                                                        name="favourite_venue"
                                                        className="profile_input"
                                                        aria-label="Default select example"
                                                        value={
                                                            inputFields?.favourite_venue ||
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(e)
                                                        }
                                                    >
                                                        <option value="">
                                                            {t("Select Favourite Venue")}
                                                        </option>
                                                        {venture?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <>
                                                                        <option
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group
                                                    className="mb-3 profile_col45"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                                                        {t("Date of Birth")}{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize:
                                                                    "1.2rem",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        defaultValue={
                                                            (data &&
                                                                dobValue[0]
                                                                    ?.dob) ||
                                                            ""
                                                        }
                                                        className="profile_inpu"
                                                        type="date"
                                                        required
                                                        name="dob"
                                                        max={"9999-12-31"}
                                                        onChange={(e) =>
                                                            handleInputChange1(
                                                                e
                                                            )
                                                        }
                                                        placeholder="DOB"
                                                        style={{
                                                            height: "40px",
                                                            direction: "rtl",
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <Button
                                                className={`profile_button ${Language === "ar" && "d-flex justify-content-end"}`}
                                                variant="primary"
                                                type="submit"
                                            >
                                                {t("Update")}
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            ) : (
                                <Loader />
                            )}

                            <div className="mt-4 mb-4">
                                <h3 className="profile_heading">
                                    {t("Update Profile Picture")}
                                </h3>
                                <Form className={`${Language === "ar" && "d-flex flex-column align-items-end"}`} onSubmit={fileUpload}>

                                    <Form.Group
                                        className={`mb-3 profile_col45 ${Language === "ar" && "text-end"}`}
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="profile_title">
                                            {t("Upload profile Pic")}
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                setFiles(e.target.files[0])
                                            }
                                            className="profile_input"
                                            type="file"
                                            dir={Language === "ar" ? "rtl" : "ltr"}
                                        />
                                    </Form.Group>
                                    <div style={{
                                        width: "45%",
                                    }} className={`${Language === "ar" && "d-flex"}`}>
                                        {!loading1 ? (
                                            <Button
                                                disabled={loading ? true : false}
                                                type="submit"
                                                className="profile_button "
                                            >
                                                {t("Upload")}
                                            </Button>
                                        ) : (
                                            <div
                                                style={{
                                                    height: "4rem",
                                                    width: "4rem",
                                                }}
                                            >
                                                <img src={load} alt="" />
                                            </div>
                                        )}
                                    </div>

                                </Form>
                            </div>
                            <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                                <div className={`d-flex  profile_col45 ${Language === "ar" ? "justify-content-start" : "justify-content-end"}`}>
                                    <Button
                                        onClick={() => {
                                            navigate(`/addchild`);
                                        }}
                                        style={{
                                            width: "200px",
                                        }}
                                        className={`profile_button`}
                                    >
                                        {t("Add Your Child")}
                                    </Button>
                                </div>
                                <div className={`d-flex  profile_col45 ${Language === "ar" ? "justify-content-end" : "justify-content-start"}`}>
                                    <Button
                                        className="profile_button"
                                        onClick={() => {
                                            navigate(`/addadult`);
                                        }}
                                        style={{
                                            width: "200px",
                                        }}
                                    >
                                        {t("Add Additional Adult")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Profile;
