import { GET_TIME_SLOT, REMOVE_TIME_SLOTS } from "../../constants/actionTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { slot: [] }, action) => {
  switch (action.type) {
    case GET_TIME_SLOT:
      return { ...state, slot: action.payload };
    case REMOVE_TIME_SLOTS:
      return { slot: [] };

    default:
      return state;
  }
};
