import { CHANGE_PASSWORD, LOGIN, SIGNUP } from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { change: [] }, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { ...state, change: action.payload };

    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
