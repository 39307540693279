import * as api from "../../apis";
import { GET_ADDONS } from "../../constants/actionTypes";

export const getAddonsList = (start, page, search) => async (dispatch) => {
  try {
    const { data } = await api.fetchAddons(start, page, search);
    console.log(data);
    const creatUuid = () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)?.replace(/[018]/g, (c) =>
        (
          c ^
          (crypto?.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        )?.toString(16)
      );
    };

    const newData = data?.message?.map((i) => {
      return {
        ...i,
        id: creatUuid(),
      };
    });

    console.log(newData);
    dispatch({ type: GET_ADDONS, payload: newData });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
