import { GET_COLOR_LIST, GET_REALTION_LIST } from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { realtion: [] }, action) => {
  switch (action.type) {
    case GET_REALTION_LIST:
      return { ...state, realtion: action.payload };

    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
