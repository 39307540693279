import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../store/actions/authActions/authActions";
import "../../styles/common.css";
import "./forgetPassword.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setPassword } from "../../store/apis";
import { updateSetPasword } from "../../store/actions/setpasswordaction/setpasswordAction";
import { forgotPassword } from "../../store/actions/forgotPasswordAction/forgotPasswordAction";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
    const { t } = useTranslation(["main"]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Language = useSelector((state) => state?.languageReducer?.language);
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const key = urlParams?.get("key");
    console.log(key);

    const handleInputChange = (e) => {
        setInputFields((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    console.log(inputFields, "email");

    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(forgotPassword(inputFields?.email, navigate, setLoading));
    };
    return (
        <div className="d-flex justify-content-center profile_wrapper">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div
                className={
                    Language === "en" ? "forgetPassword" : "forgetPasswordAr"
                }
            >
                <h2 className="profile_heading mb-4">{t("Forgot Password")}</h2>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="profile_title">
                            {t("Enter email")}
                            <span
                                style={{
                                    color: "red",
                                    fontSize: "1.2rem",
                                }}
                            >
                                *
                            </span>
                        </Form.Label>
                        <Form.Control
                            required
                            value={inputFields?.email || ""}
                            name="email"
                            className="profile_input"
                            onChange={(e) => handleInputChange(e)}
                            type="email"
                            placeholder={t("Email")}
                        />
                    </Form.Group>
                    <div className="text-center">
                        <Button
                            disabled={loading ? true : false}
                            className="profile_button w-100 mt-4 mb-4"
                            variant="primary"
                            type="submit"
                        >
                            {loading
                                ? `${t("Sending Email")}...`
                                : `${t("Reset Password")}`}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ForgotPassword;
