export const LOGIN = "LOGIN";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SIGNUP = "SIGNUP";
export const USER = "USER";
export const ADD_ADULT = "ADD_ADULT";
export const GET_CITY = "GET_CITY";
export const GET_CHARACTER_LIST = "GET_CHARACTER_LIST";
export const GET_COLOR_LIST = "GET_COLOR_LIST";
export const GET_REALTION_LIST = "GET_REALTION_LIST";
export const UPDATE_CHILD = "UPDATE_CHILD";
export const FAVOURITE_VENTURE = "FAVOURITE_VENTURE";
export const NATIONALITY = "NATIONALITY";
export const SET_PASSWORD = "SET_PASSWORD";
export const GET_ITEM_LIST = "GET_ITEM_LIST";
export const GET_ITEM_INFO = "GET_ITEM_INFO";
export const FLOW = "FLOW";
export const REMOVE_FLOW = "REMOVE_FLOW";
export const EMPTY_FLOW = "EMPTY_FLOW";
export const GET_BRAND_LIST = "GET_BRAND_LIST";
export const GET_BRANCH_LIST = "GET_BRANCH_LIST";
export const GET_TIME_SLOT = "GET_TIME_SLOT";
export const GET_PHONE_LIST = "GET_PHONE_LIST";
export const GET_CART_INFO = "GET_CART_INFO";
export const GET_ADDONS = "GET_ADDONS";
export const CLEAR_USER = "CLEAR_USER";
export const PLACE_ORDER = "PLACE_ORDER";
export const COUPON_CODE = "COUPON_CODE";
export const REMOVE_PLACE_ORDER = "REMOVE_PLACE_ORDER";
export const REMOVE_TIME_SLOTS = "REMOVE_TIME_SLOTS";
export const PLACE_ORDER_DETAILS = "PLACE_ORDER_DETAILS";
export const REMOVE_PLACE_ORDER_DETAILS = "REMOVE_PLACE_ORDER_DETAILS";
export const ORDER_LIST = "ORDER_LIST";
export const SALES_ORDER_DETAILS = "SALES_ORDER_DETAILS";
