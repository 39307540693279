import * as api from "../../apis";
import { GET_COLOR_LIST } from "../../constants/actionTypes";

export const getColorList = (setInputFields) => async (dispatch) => {
  try {
    const { data } = await api.fetchColorList();
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: GET_COLOR_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
