import { GET_ITEM_LIST } from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { item: [] }, action) => {
  switch (action.type) {
    case GET_ITEM_LIST:
      return { ...state, item: action.payload };

    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
