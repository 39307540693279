import React from "react";
import loader from "../../assets/images/loader.svg";

export const Loader = () => {
  return (
    <div>
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ width: "100%", height: "50vh" }}
      >
        <img src={loader} alt="" />
      </div>
    </div>
  );
};
