import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../store/actions/authActions/authActions";
import "../../styles/common.css";
import "./SetPassoword.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setPassword } from "../../store/apis";
import { updateSetPasword } from "../../store/actions/setpasswordaction/setpasswordAction";
import { useTranslation } from "react-i18next";


const SetPassoword = () => {
  const { t } = useTranslation(["main"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Language = useSelector((state) => state?.languageReducer?.language);
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const key = urlParams?.get("key");
  console.log(key);

  const handleInputChange = (e) => {
    setInputFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  console.log(inputFields, key);

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      updateSetPasword(key, inputFields?.password, navigate, setLoading, t)
    );
  };
  return (
    <div className="d-flex justify-content-center profile_wrapper">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className={`${Language === "ar" && "setPassword"}`}>
        <h2 className="profile_heading mb-4">{t("Reset Password")}</h2>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="profile_title">
              {t("New Password")}
              <span
                style={{
                  color: "red",
                  fontSize: "1.2rem",
                }}
              >
                *
              </span>
            </Form.Label>
            <Form.Control
              required
              value={inputFields?.password || ""}
              name="password"
              className="profile_input"
              onChange={(e) => handleInputChange(e)}
              type="password"
              placeholder={t("Password")}
            />
          </Form.Group>
          <div className="text-center">
            <Button
              disabled={loading ? true : false}
              className="profile_button w-100 mt-4 mb-4"
              variant="primary"
              type="submit"
            >
              {loading ? `${t("Updating")}...` : `${(t("Update"))}`}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SetPassoword;
