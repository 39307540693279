import React, { useEffect, useState } from "react";
import "./header.css";
import logo from "../../assets/images/logo.png";
import { Dropdown, Nav } from "react-bootstrap";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { ClearUserData } from "../../store/actions/userData/userDataAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LanguageChnageAction } from "../../store/actions/languageAction/languageAction";
import * as api from "../../store/apis";

export const Header = () => {
    const { t, i18n } = useTranslation(["main"]);
    const Language = useSelector((state) => state?.languageReducer?.language);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let sid;

    sid = Cookies.get("sid");

    const remove = async () => {
        let { status } = await api.Logout()
        if(status === 200){
            Cookies.remove("sid");
            dispatch(ClearUserData());
            navigate("/");
            window.location.reload();
        }
    };

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("en");
        }
        if (localStorage.getItem("i18nextLng") === "en" || "en-US") {
            setSelectedLanguage("English");
        } else if (localStorage.getItem("i18nextLng") === "ar") {
            setSelectedLanguage("Arabic");
        }
    }, []);

    const handleLanguageSelect = (language, lan) => {
        i18n.changeLanguage(lan);
        dispatch(LanguageChnageAction(lan));
        setSelectedLanguage(language);
    };

    console.log()

    const showBooking = ()=>{
        let sid = Cookies.get("sid");
        let path = window.location.pathname;
        return sid ? true : false
    }

    return (
        <div>
            <div className="patoi-nav">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <a className="navbar-brand" href="/">
                            <img src={logo} alt="logo" />
                        </a>
                        <div
                            className="collapse navbar-collapse mean-menu"
                            style={{ display: "block" }}
                        >
                            <ul className="navbar-nav">
                                {/* <li className="nav-item">
                  <a href="/" className="nav-link active">
                    Home
                  </a>
                </li> */}
                                {/* <li className="nav-item">
                  <a href="/aboutus" className="nav-link ">
                    About Us
                  </a>
                </li> */}
                                {/* <li className="nav-item">
                  <a href="#" className="nav-link">
                    Event Ticket Booking
                  </a>
                </li> */}
                                {/* <li className="nav-item">
                  <a href="/partybooking" className="dropdown-toggle nav-link ">
                    Party Booking
                  </a>
                </li> */}
                                {/* <li className="nav-item">
                  <a href="#" className="nav-link ">
                    Contact us
                  </a>
                </li> */}
                                <a href="/partybooking" hidden={!showBooking()}>
                                    <button className="btn-primary btn-booking">{t("Book Now")}</button>
                                </a>
                            </ul>
                            <div className="others-option">
                                <div className="d-flex align-items-center">
                                    <ul className="d-flex">
                                        <li>
                                            <div className="search-icon">
                                                <i className="bx bx-search"></i>
                                            </div>
                                        </li>
                                        {sid?.length > 0 ? (
                                            <Dropdown className="loginDrop">
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                >
                                                    {sid}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/myprofile">
                                                        {t("My Account")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={remove}
                                                    >
                                                        {t("Log Out")}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : (
                                            <Dropdown className="loginDrop">
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                >
                                                    {t("My Account")}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/">
                                                        {t("Sign In")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="/signup">
                                                        {t("Sign Up")}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                        <div className="languageDropdwon">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="warning"
                                                    id="dropdown-basic"
                                                >
                                                    {Language === "en"
                                                        ? "English"
                                                        : "عربي"}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleLanguageSelect(
                                                                "English",
                                                                "en"
                                                            )
                                                        }
                                                    >
                                                        English
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleLanguageSelect(
                                                                "Arabic",
                                                                "ar"
                                                            )
                                                        }
                                                    >
                                                        عربي
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                        {/* <li className="dropdown">
                      <a
                        href="javascript:void(0)"
                        className="dropdown-toggle d-flex"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bx bx-user-circle me-2"></i>{" "}
                        <span className="f-12">My Account</span>
                      </a>
                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="https://telewiz.in/vim/login"
                          >
                            Sign in / Login
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="https://telewiz.in/vim/register"
                          >
                            Sign up / Register
                          </a>
                        </li>
                      </ul>
                    </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};
