import { combineReducers } from "redux";
import authReducers from "./authReducers/authReducers";
import userReducer from "./userReducers/userReducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import cityReducer from "./cityReducer/cityReducer";
import colorReducer from "./colorReducer/colorReducer";
import characterReducer from "./characterReducer/characterReducer";
import realtionReducer from "./realtionReducer/realtionReducer";
import favouriteVentureReducer from "./favouriteVentureReducer/favouriteVentureReducer";
import changePassowordReducer from "./changePassowrdReducer/changePassowordReducer";
import nationalityReducer from "./nationalityReducer/nationalityReducer";
import itemlistReducer from "./itemlistReducer/itemlistReducer";
import ItemInfoReducer from "./iteminfoReducer/ItemInfoReducer";
import flowReducer from "./flowReducer/flowReducer";
import brandlistReducer from "./brandlistReducer/brandlistReducer";
import branchReducer from "./branchReducer/branchReducer";
import getTimeSlotReducer from "./gettimeslotReducer/getTimeSlotReducer";
import phoneListReducer from "./phoneListReducer/phoneListReducer";
import cartInfoReducer from "./cartInfoReducer/cartInfoReducer";
import addonsReducer from "./addonsReducer/addonsReducer";
import placeOrderReducer from "./placeorderReducer/placeOrderReducer";
import placeOrderDetailsReducer from "./placeorderdetailsreducer/placeOrderDetailsReducer";
import orderlistReducer from "./orderlistReducer/orderlistReducer";
import salesReducer from "./salesReducer/salesReducer";
import languageReducer from "./languageReducer/languageReducer";

const persistConfig = {
    key: "root",
    storage,

    //   whitelist: ["user"],
};

const reducers = combineReducers({
    authReducers: authReducers,
    userReducer: userReducer,
    cityReducer: cityReducer,
    colorReducer: colorReducer,
    characterReducer: (persistConfig, characterReducer),
    realtionReducer: (persistConfig, realtionReducer),
    changePassowordReducer: (persistConfig, changePassowordReducer),
    favouriteVentureReducer: (persistConfig, favouriteVentureReducer),
    nationalityReducer: (persistConfig, nationalityReducer),

    itemlistReducer: (persistConfig, itemlistReducer),
    ItemInfoReducer: (persistConfig, ItemInfoReducer),
    languageReducer: (persistConfig, languageReducer),
    flowReducer: (persistConfig, flowReducer),
    brandlistReducer: brandlistReducer,
    branchReducer: branchReducer,
    getTimeSlotReducer: getTimeSlotReducer,
    phoneListReducer: phoneListReducer,
    cartInfoReducer: cartInfoReducer,
    addonsReducer: addonsReducer,
    placeOrderReducer: placeOrderReducer,
    placeOrderDetailsReducer: placeOrderDetailsReducer,
    orderlistReducer: orderlistReducer,
    salesReducer: salesReducer,
});

export default persistReducer(persistConfig, reducers);
