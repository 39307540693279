import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ProfileSideBar from "../../components/profilesidebar/ProfileSideBar";
import { getCharacterList } from "../../store/actions/characterListAction/characterListAction";
import { getColorList } from "../../store/actions/colorAction/colorAction";
import { getUserData } from "../../store/actions/userData/userDataAction";
import { updateUserData } from "../../store/apis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/common.css";
import { updateChildList } from "../../store/actions/updateChildAction/UpdateChildAction";
import { useTranslation } from "react-i18next";
const EditChild = () => {
  const Language = useSelector((state) => state?.languageReducer?.language);
  const { t } = useTranslation(["main"]);
  const params = useParams();
  const itemId = params?.id;

  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editChild, setEditChild] = useState();
  const [inputFields, setInputFields] = useState({
    customer_family_detail: [],
  });
  console.log(inputFields);
  const [adult, setAdult] = useState({
    adult: 0,
    child: 1,
  });

  const dispatch = useDispatch();

  const AllDataList = useSelector(
    (state) => state?.userReducer?.user?.message?.customer_data
  );
  const data = useSelector(
    (state) =>
      state?.userReducer?.user?.message?.customer_data?.customer_family_detail
  );
  console.log(data);
  const filterData = data?.filter((item, index) => {
    return item.child === 1;
  });
  useEffect(() => {
    setEditChild(
      filterData?.filter((item, index) => item?.idx === Number(itemId))[0]
    );
  }, [data]);

  console.log(editChild);

  useEffect(() => {
    dispatch(getUserData(setInputFields));
    dispatch(getCharacterList());
    dispatch(getColorList());
  }, []);

  const character = useSelector(
    (state) => state?.characterReducer?.character?.message
  );

  const color = useSelector((state) => state?.colorReducer?.color?.message);

  useEffect(() => {
    if (update) {
      dispatch(getUserData(setInputFields));
      setUpdate(false);
    }
  }, [update]);
  useEffect(() => {
    // dispatch(updateUserData(inputFields));
    console.log(inputFields);
    if (editable) {
      updateFamilyData();
    }
  }, [inputFields?.customer_family_detail, editable]);

  const updateFamilyData = async () => {
    try {
      const res = await updateUserData(inputFields);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditChild((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  console.log(editChild);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let familiyData = inputFields?.customer_family_detail;
  //   let mydata = familiyData?.concat(adult);
  //   setInputFields((prev) => {
  //     return {
  //       ...prev,
  //       customer_family_detail: mydata,
  //     };
  //   });
  //   setEditable(true);
  // };
  console.log(AllDataList);
  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(updateChildList(editChild));
    const allData = AllDataList.customer_family_detail?.map((i, k) => {
      if (i.idx === Number(itemId)) {
        return Object.assign(i, { ...editChild });
      }
      return i;
    });

    const all = Object.assign(AllDataList, { customer_family_detail: allData });
    try {
      updateUserData(all);
      toast.success("Added Succesfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdate(true);
      window.location.replace(`/addchild`);
    } catch { }
  };
  return (
    <div className="profile_wrapper addChild">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Container>
        <Row className={`${Language === "ar" && "flex-row-reverse"}`}>
          <Col md lg="4">
            <div>
              <ProfileSideBar />
            </div>
          </Col>
          <Col md lg="8">
            <div>
              <h3 className="profile_heading">{t("Edit Child")}</h3>
              <div>
                <Form className={Language === "ar" && "addChildAr"} onSubmit={(e) => handleSubmit(e)}>
                  <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <Form.Group
                      className="mb-3 profile_col20"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span>{t("Gender")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Select
                        className="profile_input"
                        name="gender"
                        aria-label="Default select example"
                        required
                        value={editChild?.gender || ""}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option
                          selected={
                            editChild?.gender?.length > 0 ? false : true
                          }
                          value={editChild?.gender}
                        >
                          {editChild?.gender === "Female" ? t("Girl") : t("Boy")}
                        </option>
                        <option value="Male">{t("Boy")}</option>
                        <option value="Female">{t("Girl")}</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label
                        className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}

                      >
                        <span>{t("Child Name")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        className={`profile_input ${Language === "ar" && "alignright"}`}
                        type="text"
                        name="child_name"
                        defaultValue={editChild?.child_name}
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Child Name"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="profile_title">
                        {t("School Name")}
                      </Form.Label>
                      <Form.Control
                        className={`profile_input ${Language === "ar" && "alignright"}`}
                        type="text"
                        name="school_name"
                        defaultValue={editChild?.school_name || ""}
                        onChange={(e) => handleInputChange(e)}
                        placeholder=" School Name"
                      />
                    </Form.Group>
                  </div>
                  <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <Form.Group
                      className="mb-3 profile_col20"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="profile_title">
                        {t("Favorite Character")}
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => handleInputChange(e)}
                        className="profile_input"
                        name="favorite_character"
                        aria-label="Default select example"
                        value={editChild?.favorite_character}
                      >
                        <option value="">{t("Select Favorite Character")}</option>
                        {character?.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="profile_title">
                        {t("Favorite Color")}
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => handleInputChange(e)}
                        className="profile_input"
                        name="favourite_colour"
                        aria-label="Default select example"
                        value={editChild?.favourite_colour}
                      >
                        <option value="">{t("Select Favorite Color")}</option>
                        {color?.map((item, index) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="profile_title">
                        <span> {t("Date of Birth")}{" "}</span>
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.2rem",
                          }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        className="profile_input"
                        type="date"
                        defaultValue={editChild?.dob}
                        name="dob"
                        max={"9999-12-31"}
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Enter email"
                      />
                    </Form.Group>
                  </div>

                  <Button
                    className={`profile_button  mt-4 mb-4 ${Language === "ar" && "d-flex"}`}
                    variant="primary"
                    type="submit"
                  >
                    {t("Update")}
                  </Button>
                </Form>
              </div>

              <div className={`d-flex justify-content-center ${Language === "ar" && "flex-row-reverse"}`}>
                <Button
                  onClick={() => {
                    navigate(`/myprofile`);
                  }}
                  className={`profile_button ${Language === "ar" ? "btnArMargin" : "me-4"}`}
                >
                  {t("My Profile")}
                </Button>
                <Button
                  className="profile_button"
                  onClick={() => {
                    navigate(`/addadult`);
                  }}
                >
                  {t("Add Additional Adult")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditChild;
