import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./CardDetail.css";
import Funtazia from "../../assets/images/Funtazia.png";
import offer4 from "../../assets/images/offer4.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getItemInfo } from "../../store/actions/iteminfoAction/itemInfoAction";
import { useSelector } from "react-redux";
import {
  removeFlow,
  updateFlow,
} from "../../store/actions/flowAction/flowAction";
import { removeTimeSlots } from "../../store/actions/gettimeslotsAction/getTimeSlotAction";
import { useTranslation } from "react-i18next";

export const CardDetail = () => {
  const { t } = useTranslation(["main"]);
  const dispatch = useDispatch();
  const params = useParams();
  const id = params?.id;
  const data = useSelector((state) => state?.flowReducer?.flow);
  const [inputFields, setInputFields] = useState({
    item_code: id,
  });
  const info = useSelector((state) => state?.ItemInfoReducer?.info?.message);
  console.log(info);

  console.log(data);
  useEffect(() => {
    dispatch(getItemInfo(id));
    if (inputFields?.item_code) {
      const allData = { ...data, ...inputFields };
      dispatch(updateFlow(allData));
    }
  }, []);

  const navigate = useNavigate();
  const a = [
    {
      id: 1,
      image: Funtazia,
    },
    {
      id: 2,
      image: offer4,
    },
    {
      id: 3,
      image: Funtazia,
    },
    {
      id: 4,
      image: Funtazia,
    },
    {
      id: 5,
      image: Funtazia,
    },
  ];
  const [selected, setSelected] = useState(a[0]);

  const selectImg = (item) => {
    setSelected(item);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/progresspage");
    if (inputFields?.item_code) {
      const allData = { ...data, ...inputFields };
      dispatch(updateFlow(allData));
    }
    dispatch(removeFlow());
    dispatch(removeTimeSlots());
  };

  return (
    <div>
      <Container>
        <Row className="d-flex justify-content-between">
          <Col md lg="6">
            <div className="d-flex align-items-center ">
              <div className="me-4">
                {a.map((item, index) => {
                  return (
                    <div
                      onClick={() => selectImg(item)}
                      className={`${selected?.id === item?.id ? "carddetail_border" : null
                        } carddetail_sideImg`}
                    >
                      <img src={item?.image} alt="" />
                    </div>
                  );
                })}
              </div>
              <div className="CardDetail_mainImg">
                <img src={`https://${info?.website_image}`} alt="code" />
              </div>
            </div>
          </Col>
          <Col md lg="5">
            <div className="carddetail_wrapper">
              <div>
                <h2 className="carddetail_heading text-center">{info?.name}</h2>

                <p className="carddetail_title">{t("Item Code")}: {info?.item_code}</p>
                <h2 className="carddetail_price ">{info?.formatted_price} </h2>
              </div>
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: info?.description }}
                ></div>
                {/* <h3>{info?.description}</h3> */}
              </div>
              <div className="text-center">
                <Button onClick={handleSubmit} className="carddetail_button ">
                  {t("Add to Cart")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
