import * as api from "../../apis";
import { PLACE_ORDER, REMOVE_PLACE_ORDER } from "../../constants/actionTypes";

export const getPlaceOrder = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPlaceOrder();
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: PLACE_ORDER, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const removeOrderName = () => async (dispatch) => {
  try {
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: REMOVE_PLACE_ORDER });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
