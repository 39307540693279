import {
  EMPTY_FLOW,
  FAVOURITE_VENTURE,
  FLOW,
  REMOVE_FLOW,
} from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    flow: {
      item_code: "",
      qty: 0,
      date: "",
      additional_notes: "",
      branch: "",
      brand: "",
      delivery_date: "",
      phone: "",
      slot: "",
      uom: "",
    },
  },
  action
) => {
  switch (action.type) {
    case FLOW:
      return {
        ...state,
        flow: action.payload,
      };
    case REMOVE_FLOW:
      return {
        flow: {
          ...state.flow,
          qty: 0,
          delivery_date: "",
          additional_notes: "",
          branch: "",
          brand: "",

          delivery_date: "",
          phone: "",
          slot: "",
          uom: "",
        },
      };
    case EMPTY_FLOW:
      return {
        flow: {},
      };
    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
