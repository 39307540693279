import axios from "axios";
import Cookies from "js-cookie";

let sid;

if (Cookies.get("sid")) {
    sid = Cookies.get("sid");
    console.log(sid);
}

const API = axios.create({
  baseURL: "https://vimtrialtemp.etoserp.com",

  withCredentials: true,
});

// API.interceptors.request.use((req) => {
//     console.log(req);
//     if (Cookies.get("data")) {
//         const tokenString = JSON.parse(Cookies.get("data"));
//         console.log(tokenString);
//         const sid = tokenString?.message?.data?.sid;

//         req.headers[
//             "Cookie"
//         ] = `user_image=${""}; sid=${sid}; system_user=yes; full_name=${
//             tokenString.full_name
//         }; user_id=${tokenString?.message?.data?.user}`;
//     }

//     return req;
// });

export const fetchLogin = (number, password) =>
    API.post(`api/method/login`,
        {
            usr: number,
            pwd: password,
            device: "mobile"
        },
        {
            headers: {
                "Content-type": "application/json",
            },
        });

export const Logout = () =>
    API.post(`api/method/logout`);

export const fetchLoggedUser = () =>
    API.get(`api/method/frappe.auth.get_logged_user`);

export const fetchChangePassword = (old_password, new_password) =>
    API.post(
        `api/method/vim.restapi.reset_password?old_password=${old_password}&new_password=${new_password}`
    );
export const fetchSignup = (name, email, mobile, city) =>
    API.post(
        `/api/method/vim.restapi.sign_up?email=${email}&full_name=${name}&mobile=${mobile}&city=${city}`
    );
export const updateUserData = (inputFields) =>
    API.post(`/api/method/vim.restapi.update_data`, inputFields, {
        // headers: {
    });
export const setPassword = (key, password) =>
    API.post(
        `/api/method/vim.api.update_password?key=${key}&new_password=${password}`
    );
export const fetchUserData = () =>
    API.get(`/api/method/vim.restapi.get_customerdata`, {});
export const fetchResendEmail = (email) =>
    API.get(`/api/method/vim.restapi.resend_welcome?email_id=${email}`);

export const fetchNationalityList = () =>
    API.get(`/api/method/vim.restapi.get_country_list`, {});

export const fetchCityList = () =>
    API.get(`/api/method/vim.restapi.get_city_list`, {});
export const fetchCharacterList = () =>
    API.get(`/api/method/vim.restapi.get_characters_list`, {});
export const fetchColorList = () =>
    API.get(`/api/method/vim.restapi.get_color_list`, {});
export const fetchRealtionList = (type) =>
    API.get(`/api/method/vim.restapi.get_relation_list?type=${type}`);
export const fetchfavouriteVenture = () =>
    API.get(`/api/method/vim.restapi.get_brand_list`, {});
export const fetchBrandList = () =>
    API.get(`/api/method/vim.restapi.get_brand_list`, {});
export const fetchBranchList = (brand) =>
    API.post(`api/method/vim.restapi.get_branch_list`, { brand });
export const fetchItemList = (start, per) =>
    API.post(
        `/api/method/vim.restapi.get_event_items?start=${start}&per_page=${per}`,
        {}
    );
export const fetchIsSlot = (item, date, branch) => {
    return API.post(
        `/api/method/vim.restapi.is_available?item_code=${item}&visit_date=${date}&branch=${branch}`
    );
};
export const fetchTimeSlots = (item, date, branch, uom) => {
    return API.post(
        `/api/method/vim.restapi.get_slot_list?item_code=${item}&visit_date=${date}&branch=${branch}&unit=${uom}`
    );
};
export const fetchItemInfo = (id) =>
    API.post(`/api/method/vim.restapi.get_product_details?item_code=${id}`);
export const fetchIsInternal = () =>
    API.post(`/api/method/vim.restapi.check_internal_user`);
export const fetchUpdateCart = (
    item_code,
    qty,
    // additional_notes,
    branch,
    brand,
    delivery_date,
    phone,
    slot,
    uom,
    customer_name
) =>
    API.post(
        `/api/method/vim.restapi.update_in_cart?item_code=${item_code}&qty=${qty}&branch=${branch}&brand=${brand}
    &delivery_date=${delivery_date}&phone=${phone}&slot=${slot}&uom=${uom}&customer_name=${customer_name}`
    );
export const fetchUpdateAddons = (item_code, qty) => {
    return API.post(
        `/api/method/vim.restapi.update_in_cart?item_code=${item_code}&qty=${qty}`
    );
};
export const fetchOrderList = () => {
    return API.post(`/api/method/vim.restapi.get_order_list`);
};
export const fetchSalesOrderDetails = (id) => {
    return API.post(
        `/api/method/vim.restapi.get_order_details?sales_order=${id}`
    );
};
export const fetchPlaceOrder = () => {
    return API.post(`/api/method/vim.restapi.place_order`);
};
export const submitOrder = (value, payment_status = '', transactionID = '') => {
    return API.post(
        `/api/method/vim.restapi.place_order?advance_amount=${value}&payment_status=${payment_status}&transactionid=${transactionID}`
    );
};
export const fetchPlaceOrderDetails = (name) => {
    return API.post(
        `/api/method/vim.restapi.get_order_details?sales_order=${name}`
    );
};
export const fetchPhoneList = () =>
  API.post(
    `https://vimtrialtemp.etoserp.com/api/method/vim.restapi.get_customer_phone_list`
  );
export const fetchCartInfo = () => API.post(`/api/method/vim.restapi.get_cart`);
export const fetchForgotPassword = (email) =>
    API.post(`/api/method/vim.restapi.forgot_passwrod?email_id=${email}`);

export const fetchAddons = (start, page, search) =>
    API.post(
        `/api/method/vim.restapi.get_items?start=${start}&per_page=${page}&search=${search}`
    );
export const fetchApplyCouponCode = (code) =>
    API.post(`/api/method/vim.restapi.apply_coupon_code?applied_code=${code}`);
export const attachPhoto = (mail, file) => {
    return API.put(`/api/resource/User/${mail}?=`, { user_image: file });
};
export const getSocialLogin = () => {
    return API.get("api/method/vim.restapi.getSocialLogins");
};
export const getCustomerName = (data) => {
    return API.get(`api/method/vim.restapi.get_customer_name?phone=${data}`);
};
export const uploadProfile = (file) =>
    API.post(`/api/method/upload_file`, file, {
        headers: {
            "Content-type": "multipart/form-data",
        },
    });
