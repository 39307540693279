import { EMPTY_FLOW, FLOW, REMOVE_FLOW } from "../../constants/actionTypes";

export const updateFlow = (inputFields) => async (dispatch) => {
  try {
    dispatch({ type: FLOW, payload: inputFields });
  } catch (error) {
    console.log(error);
  }
};
export const removeFlow = () => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_FLOW });
  } catch (error) {
    console.log(error);
  }
};
export const emptyFlow = () => async (dispatch) => {
  try {
    dispatch({ type: EMPTY_FLOW });
  } catch (error) {
    console.log(error);
  }
};
