import * as api from "../../apis";
import { CLEAR_USER, LOGIN, SIGNUP, USER } from "../../constants/actionTypes";
import Cookies from "js-cookie";

export const getUserData = (setInputFields) => async (dispatch) => {
  try {
    const { data } = await api.fetchUserData();
    setInputFields(data?.message?.customer_data);
    dispatch({ type: USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const ClearUserData = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
