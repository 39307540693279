import React from "react";
import "./footer.css";
import logo from "../../assets/images/Vim-Logo.png";
import contact from "../../assets/images/contact.png";
import earphone from "../../assets/images/earphones.png";
import email from "../../assets/images/email.png";
import location from "../../assets/images/location.png";
import xp from "../../assets/images/xp.png";
import daz from "../../assets/images/daz.png";
import american from "../../assets/images/american.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

export const Footer = () => {
    const { t, i18n } = useTranslation(["main"]);
    const Language = useSelector((state) => state?.languageReducer?.language);
    const sid = Cookies.get("sid");

    const showBooking = ()=>{
        let sid = Cookies.get("sid");
        let path = window.location.pathname;
        return sid ? true : false
    }

    return (
        <footer className={Language === "en" ? "footer-area" : "footer-area rtl"}>
            <div className="container">
                <div className="me-4 ms-4">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <a href="/" className="logo">
                                    <img
                                        style={{ width: "20rem" }}
                                        src={logo}
                                        alt="logo"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-end justify-content-end">
                            <div className="single-footer-widget ">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="single-footer-widget">
                                        <a href="/partybooking" hidden={!showBooking()}>
                                            <button className="btn-primary btn-booking">{t("Book Now")}</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conatiner">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 ">
                                <ul className="footer-contact-info">
                                    <li className="logoicon-img-div">
                                        <span>
                                            <img src={earphone} alt="" />
                                        </span>{" "}
                                        <a href="tel:800 244 0306">
                                            800 244 0306
                                        </a>
                                    </li>
                                    <li className="logoicon-img-div">
                                        <span>
                                            <img src={contact} alt="" />
                                        </span>{" "}
                                        <a href="tel:012 606 4444">
                                            {" "}
                                            012 606 4444
                                        </a>
                                    </li>
                                    <li className="logoicon-img-div">
                                        <span>
                                            {" "}
                                            <img src={email} alt="" />
                                        </span>{" "}
                                        <a href>info@vim.sa</a>
                                    </li>
                                    <li className="logoicon-img-div">
                                        <span>
                                            {" "}
                                            <img src={location} alt="" />
                                        </span>{" "}
                                        Address: sultan mall, Jeddah, KSA
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-end justify-content-center ">
                                <p style={{ fontSize: "16px" }}>
                                    Copyright @2022{" "}
                                    <span>Vim Entertainment</span>
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-end justify-content-end">
                                <div className="xpimg-div me-4">
                                    <a target="_blank" href="https://xtremeplay.sa" rel="noreferrer">
                                        <img src={xp} alt="" />
                                    </a>
                                </div>
                                <div className="xpimg-div">
                                    <a target="_blank" href="https://daz.sa" rel="noreferrer">
                                        <img src={daz} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="copyright-area">
        <div className="container">
          <p>
            Copyright @2022 <span>Vim Entertainment</span>
          </p>
        </div>
      </div> */}
            </div>
        </footer>
    );
};
