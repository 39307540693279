import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signUpRequest } from "../../store/actions/authActions/authActions";
import { fetchResendEmail, fetchSignup } from "../../store/apis";
import "../../styles/common.css";
import "./signup.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCityList } from "../../store/actions/cityAction/cityAction";
import { Loader } from "../../components/loader/Loader";
import { getNationalityList } from "../../store/actions/nationalityAction/nationalityAction";
import { useTranslation } from "react-i18next";


const Signup = () => {
    const [isEmailSend, setIsEmailSend] = useState(false);
    const [isRedsendLoading, setIsRedsendLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Language = useSelector((state) => state?.languageReducer?.language);
    const { t } = useTranslation(["main"]);
    // const [defaultCity, setDefaultCity] = useState();
    const [inputFields, setInputFields] = useState({
        name: "",
        email: "",
        mobile: "",
        city: "Jeddah",
    });
    console.log(inputFields);
    const [loading, setLoading] = useState(false);
    const city = useSelector((state) => state?.cityReducer?.city?.message);

    useEffect(() => {
        dispatch(getCityList());

        // setInputFields(data);
    }, []);

    const handleInputChange = (e) => {
        setInputFields((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        var regex = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
        if (regex.test(inputFields?.mobile)) {
            if (inputFields?.city?.length > 0 && inputFields?.city !== "") {
                try {
                    const res = await fetchSignup(
                        inputFields?.name,
                        inputFields?.email,
                        inputFields?.mobile,
                        inputFields?.city
                    );
                    if (res && res?.data?.message[1] !== "Already Registered") {
                        setIsEmailSend(true);
                        toast.success(
                            `${t("Please check your email to complete signup")}`,
                            {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            }
                        );
                        setInputFields({
                            ...inputFields,
                            name: "",
                            mobile: "",
                            city: "Jeddah",
                        });
                        setLoading(false);

                    } else {
                        toast.error(`${t("user already registered")}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        setLoading(false);
                        setInputFields({
                            name: "",
                            email: "",
                            mobile: "",
                            city: "Jeddah",
                        });
                    }
                    // navigate("/login");
                } catch (error) {
                    toast.error(" Something is Wrong", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    console.log(error);
                    setLoading(false);
                    setInputFields({
                        name: "",
                        email: "",
                        mobile: "",
                        city: "Jeddah",
                    });
                }
            } else {
                toast.error(`${t("Enter City")}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoading(false);
            }
        } else {
            toast.error(`${t("please enter valid number")}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
            setInputFields({
                name: "",
                email: "",
                mobile: "",
                city: "Jeddah",
            });
        };
    }
    const resendEmail = async () => {
        setIsRedsendLoading(true)
        try {
            if (isEmailSend && inputFields?.email) {
                const res = await fetchResendEmail(
                    inputFields?.email
                );
                if (res.status === 200) {
                    toast.success(`${t("Email sent successfully")}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setIsRedsendLoading(false)
                    setInputFields({
                        name: "",
                        email: "",
                        mobile: "",
                        city: "Jeddah",
                    });
                }

            } else {
                setIsRedsendLoading(false)
                toast.error(`${t("Something is Wrong")}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            setIsRedsendLoading(false)
            toast.error(`${t("Something is Wrong")}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    return (
        <div className="profile_wrapper">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {
                isEmailSend &&
                <div className="profile_resend_container">
                    <div className="profile_resend">
                        <h6>
                            Congratulations! <br /> <br /> Your signup is complete. We've sent you a confirmation email to your address. <br /> <br /> If you don't receive an email within 5 minutes, please click here to resend.
                        </h6>
                        <button onClick={resendEmail}>{isRedsendLoading ? "Sending" : "Resend"}</button>
                    </div>
                </div>
            }
            <div className="d-flex justify-content-center">
                <div className=" ">
                    <h2 className="profile_heading">
                        {t("Setup your account")}
                    </h2>
                    <Form className={Language === "ar" && "signup"} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="profile_title">
                                {t("Full Name")}{" "}
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    *
                                </span>
                            </Form.Label>
                            <Form.Control
                                required
                                value={inputFields?.name || ""}
                                className="profile_input"
                                name="name"
                                onChange={(e) => handleInputChange(e)}
                                type="text"
                                placeholder={t("Enter name")}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="profile_title">
                                {t("Email Address")}{" "}
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    *
                                </span>
                            </Form.Label>
                            <Form.Control
                                required
                                className="profile_input"
                                value={inputFields?.email || ""}
                                name="email"
                                onChange={(e) => handleInputChange(e)}
                                type="email"
                                placeholder={t("Enter email")}
                            />
                        </Form.Group>

                        {/* <div className="d-flex  "> */}
                        {/* <Form.Group
                className="mb-3 profile_col30"
                controlId="formBasicEmail"
              >
                <Form.Label className="profile_title">Code</Form.Label>
                <Form.Select
                  className="profile_input"
                  disabled
                  aria-label="Default select example"
                >
                  <option>+966</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group> */}
                        <Form.Group
                            className="mb-3 "
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="profile_title ">
                                {t("Mobile No")}{" "}
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    *
                                </span>
                            </Form.Label>

                            <Form.Control
                                required
                                name="mobile"
                                className="profile_input"
                                minLength={10}
                                value={inputFields?.mobile || ""}
                                maxLength={10}
                                type="text"
                                placeholder="05xxxxxxxx"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Form.Group>
                        {/* </div> */}

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="profile_title">
                                {t("City")}{" "}
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    *
                                </span>
                            </Form.Label>
                            <Form.Select
                                required
                                name="city"
                                className="profile_input"
                                aria-label="Default select example"
                                onChange={(e) => handleInputChange(e)}
                            >
                                <option
                                    selected={
                                        inputFields?.city === "Jeddah"
                                            ? true
                                            : false
                                    }
                                    value="makkah"
                                >
                                    {t("Makkah")}
                                </option>
                                {city?.map((item, index) => {
                                    return (
                                        <>
                                            <option value={item}>{item}</option>
                                        </>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="profile_title">
                Nationality{" "}
                <span
                  style={{
                    color: "red",
                    fontSize: "1.2rem",
                  }}
                >
                  *
                </span>
              </Form.Label>
              <Form.Select
                required
                defaultValue={defaultNationality}
                className="profile_input"
                name="nationality"
                aria-label="Default select example"
                onChange={(e) => handleInputChange(e)}
              >
                {nationality1?.map((item, index) => {
                  return (
                    <>
                      <option value={item}>{item}</option>
                    </>
                  );
                })}
              </Form.Select>
            </Form.Group> */}
                        <div className="text-center ">
                            <Button
                                disabled={loading ? true : false}
                                className="profile_button w-100 mt-4 mb-4 "
                                variant="primary"
                                type="submit"
                            >
                                {loading
                                    ? `${t("Loading")}....`
                                    : `${t("Sign Up")}`}
                            </Button>
                        </div>

                        <p className="profile_title text-center">
                            {t("Have an account?")}{" "}
                            <a style={{ textDecoration: "none" }} href="/">
                                {t("Sign in here")}
                            </a>
                        </p>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
