import { GET_CHARACTER_LIST } from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { character: [] }, action) => {
  switch (action.type) {
    case GET_CHARACTER_LIST:
      return { ...state, character: action.payload };

    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
