import { GET_ITEM_INFO } from "../../constants/actionTypes";

export default (state = { info: [] }, action) => {
  switch (action.type) {
    case GET_ITEM_INFO:
      return { ...state, info: action.payload };

    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
