import { GET_PHONE_LIST } from "../../constants/actionTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { phone: [] }, action) => {
  switch (action.type) {
    case GET_PHONE_LIST:
      return { ...state, phone: action.payload };

    default:
      return state;
  }
};
