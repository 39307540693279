import React, { useEffect, useMemo, useState } from "react";
import "./PageOne.css";
import Calendar from "react-calendar";
import { Button, Form } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { useDispatch } from "react-redux";
import { updateFlow } from "../../store/actions/flowAction/flowAction";
import { useSelector } from "react-redux";
import { getBranchList } from "../../store/actions/branchAction/branchAction";
import { getBrandList } from "../../store/actions/brandlistAction/brandListActtion";
import { fetchIsInternal, getCustomerName } from "../../store/apis";
import OutsideClickHandler from "react-outside-click-handler";
import { getPhoneList } from "../../store/actions/phoneListAction/phoneListAction";
import { useTranslation } from "react-i18next";


const PageOne = ({ onButtonClick }) => {
  const Language = useSelector((state) => state?.languageReducer?.language);
  const { t } = useTranslation(["main"]);
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [show, setShow] = useState(false);
  const [filterData, setFilterData] = useState();
  const [customerName, setCustomerName] = useState("");
  const [isCustomer, setIsCustomer] = useState(false);
  const data = useSelector((state) => state?.flowReducer?.flow);
  const brand = useSelector((state) => state?.brandlistReducer?.brand?.message);
  const branch = useSelector((state) => state?.branchReducer?.branch?.message);
  const info = useSelector((state) => state?.ItemInfoReducer?.info?.message);
  const phoneList = useSelector(
    (state) => state?.phoneListReducer?.phone?.message?.phone_list
  );

  console.log(data, "redux");
  const [inputFields, setInputFields] = useState({
    item_code: info?.item_code,
    qty: data?.qty ? data?.qty : info?.minimum_sales_quantity,
    brand: data?.brand ? data?.brand : "",
    branch: data?.branch ? data?.branch : "",
    phone: data?.phone ? data?.phone : "",
    customer_name: data?.customer_name ? data?.customer_name : "",
    // date: data?.date,
  });

  useEffect(() => {
    if (
      Number(inputFields?.qty) ||
      inputFields?.brand ||
      inputFields?.branch ||
      inputFields?.number
    ) {
      const allData = { ...data, ...inputFields };
      dispatch(updateFlow(allData));
    }

    const newFilter = phoneList?.filter((value) => {
      return value?.includes(inputFields?.phone);
    });

    if (inputFields?.phone === "") {
      setFilterData(phoneList);
    } else {
      setFilterData(newFilter);
    }
    dispatch(getBrandList());
  }, [inputFields]);

  const isInternal = async () => {
    try {
      const res = await fetchIsInternal();
      console.log(res?.data?.message?.is_interanl_user);
      setIsCustomer(res?.data?.message?.is_interanl_user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isInternal();
  }, []);
  useMemo(() => {
    if (isCustomer) {
      dispatch(getPhoneList());
    }
  }, [isCustomer]);
  useEffect(() => {
    if (inputFields?.phone?.length > 0 && inputFields?.phone?.length < 11) {
      setShow(true);
    } else if (inputFields?.phone?.length === 0) {
      setShow(false);
    }
  }, [inputFields?.phone]);

  const handlePage = (e) => {
    e.preventDefault();
    const allData = { ...data, ...inputFields };
    dispatch(updateFlow(allData));
    if (data?.brand.length > 0) {
      onButtonClick("pagetwo");
    } else {
      alert(`${t("add venue")}`);
    }
  };

  const addGuest = (e) => {
    if (Number(inputFields?.qty) < info?.maximum_sales_quantity) {
      setInputFields((prev) => {
        return { ...prev, qty: Number(Number(inputFields?.qty)) + 1 };
      });
    }
  };
  const removeGuest = (e) => {
    if (Number(inputFields?.qty) > info?.minimum_sales_quantity) {
      setInputFields((prev) => {
        return { ...prev, qty: Number(inputFields?.qty) - 1 };
      });
    }
  };

  const onBlurChange = (e) => {
    if (
      Number(inputFields?.qty) >= info?.minimum_sales_quantity &&
      Number(inputFields?.qty) < info?.maximum_sales_quantity
    ) {
      setInputFields((prev) => {
        return { ...prev, qty: e.target.value };
      });
    } else if (Number(inputFields?.qty) < info?.minimum_sales_quantity) {
      setInputFields((prev) => {
        return { ...prev, qty: info?.minimum_sales_quantity };
      });
    } else if (Number(inputFields?.qty) > info?.maximum_sales_quantity) {
      setInputFields((prev) => {
        return { ...prev, qty: info?.maximum_sales_quantity };
      });
    } else if (Number(inputFields?.qty) <= info?.maximum_sales_quantity) {
      setInputFields((prev) => {
        return { ...prev, qty: e.target.value };
      });
    }
  };

  const handleInputChange = (e) => {
    setInputFields((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };


  const setNumber = async (item) => {
    await fetchCustomerName(item);
    setInputFields((prev) => {
      return { ...prev, phone: item };
    });

    setShow(false);
  };



  const fetchCustomerName = async (e) => {
    try {
      const res = await getCustomerName(e);
      setCustomerName(res?.data?.message?.customoer_name[0])
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    if (data?.brand?.length > 0) {
      dispatch(getBranchList(data?.brand));
    }
  }, [data?.brand]);


  useEffect(() => {
    if (customerName) {
      setInputFields((prev) => {
        return { ...prev, customer_name: customerName };
      });
    }
  }, [customerName]);


  return (
    <main
      className="pt5 black-80 center"
      style={{ maxWidth: "40%", maxHeight: "30%", margin: "auto" }}
    >
      <Form className={`${Language === 'ar' && "text-end"}`} onSubmit={handlePage}>
        <h2>{t("Welcome! First things first")}...</h2>
        <p style={{ color: "#C0C0C0" }}>{t("You can always change them later")}.</p>

        <div className={`${Language === 'ar' ? "d-flex align-items-center justify-content-end my-2" : "d-flex align-items-center my-2"}`} >
          <div className={`d-flex flex-column ${Language === "ar" && "align-items-end"}`}>
            <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
              {" "}
              <span> {t("About how many guests will be attending your party?")}</span>
              <span
                style={{
                  color: "red",
                  fontSize: "1.2rem",
                }}
              >
                *
              </span>
            </Form.Label>
            <div className="d-flex ">
              <Button
                className="pageone_addButton"
                name="qty"
                onClick={removeGuest}
              >
                -
              </Button>
              <div className="count_div1 text-center">
                {" "}
                <Form.Control
                  required
                  value={Number(inputFields?.qty)}
                  className="profile_input"
                  type="text"
                  name="qty"
                  onChange={(e) => handleInputChange(e)}
                  onBlur={(e) => onBlurChange(e)}
                  placeholder="number"
                />
              </div>
              <Button
                className="pageone_addButton"
                name="qty"
                onClick={addGuest}
              >
                +
              </Button>
            </div>
          </div>
        </div>
        <Form.Group className="mb-3 profile_col40" controlId="formBasicEmail">
          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
            <span> {t("Venue")}{" "}</span>
            <span
              style={{
                color: "red",
                fontSize: "1.2rem",
              }}
            >
              *
            </span>
          </Form.Label>
          <Form.Select
            onChange={(e) => handleInputChange(e)}
            className="profile_input"
            name="brand"
            aria-label="Default select example"
            required
            value={data?.brand || ""}
          >
            <option value="">{t("Select Venue")}</option>
            {brand?.map((item, index) => {
              return <option value={item}>{item}</option>;
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 profile_col40" controlId="formBasicEmail">
          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
            <span> {t("Branch")}{" "}</span>
            <span
              style={{
                color: "red",
                fontSize: "1.2rem",
              }}
            >
              *
            </span>
          </Form.Label>
          <Form.Select
            disabled={data?.brand?.length > 0 ? false : true}
            onChange={(e) => handleInputChange(e)}
            className="profile_input"
            name="branch"
            aria-label="Default select example"
            required
            value={data?.branch || ""}
          >
            <option value="">{t("Select Branch")}</option>
            {branch?.map((item, index) => {
              return <option value={item}>{item}</option>;
            })}
          </Form.Select>
        </Form.Group>
        <div className="">
          {isCustomer && (
            <Form.Group className="mb-3 " controlId="formBasicEmail">
              <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                {t("Customer Mobile No")}{" "}
                <span
                  style={{
                    color: "red",
                    fontSize: "1.2rem",
                  }}
                >
                  *
                </span>
              </Form.Label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShow(false);
                }}
              >
                <div className="searchDiv w-100">
                  <Form.Control
                    name="phone"
                    className={`profile_input ${Language === 'ar' && "text-end"}`}
                    minLength={10}
                    value={data?.phone || ""}
                    maxLength={10}
                    type="text"
                    placeholder={t("Mobile No")}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {/* Show when filterData getter then 1 */}

                  {show && (
                    <div className="searchConatiner p-4">
                      {filterData?.map((item, index) => {
                        return (
                          <p
                            className={` ${data?.number === item.toString()
                              ? "numberSelected"
                              : null
                              } numberDiv`}
                            onClick={() => setNumber(item)}
                          >
                            {item}
                          </p>
                        );
                      })}
                    </div>)
                  }
                </div>
              </OutsideClickHandler>
            </Form.Group>
          )}
        </div>
        {inputFields?.customer_name &&
          <Form.Group className="mb-3 profile_col40" controlId="formBasicEmail">
            <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
              <span> {t("Customer Name")}{" "}</span>
              <span
                style={{
                  color: "red",
                  fontSize: "1.2rem",
                }}
              >
                *
              </span>
            </Form.Label>
            <Form.Control
              required
              disabled={true}
              className={`profile_input ${Language === "ar" && "alignright"}`}
              type="text"
              name="last_name"
              value={inputFields?.customer_name}
              placeholder={t("Last Name")}
            />
          </Form.Group>}
        <div className="text-center mt-4 mb-4">
          <Button className="nextPagebtn" type="submit" value="Create Workspace">
            {t("Go to next Page")}
          </Button>
        </div>
      </Form>
    </main>
  );
};

export default PageOne;
