import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileSideBar from "../../components/profilesidebar/ProfileSideBar";
import { getUserData } from "../../store/actions/userData/userDataAction";
import { updateUserData } from "../../store/apis";
import "../../styles/common.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getRealtionList } from "../../store/actions/realtionAction/realtionAction";
import edit from "../../assets/images/create.png";
import delete1 from "../../assets/images/delete.png";
import { Loader } from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import "./AddAdult.css";


const AddAdult = () => {
  const Language = useSelector((state) => state?.languageReducer?.language);
  const { t } = useTranslation(["main"]);
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [editable, setEditable] = useState(false);
  const [inputFields, setInputFields] = useState({
    customer_family_detail: [],
  });
  const [adult, setAdult] = useState({
    adult: 1,
    child: 0,
  });

  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state?.userReducer?.user?.message?.customer_data
  );
  const realtion = useSelector(
    (state) => state?.realtionReducer?.realtion?.message
  );

  const filterData = data?.customer_family_detail?.filter((item, index) => {
    return item.adult === 1;
  });
  console.log(filterData);

  useEffect(() => {
    dispatch(getUserData(setInputFields));
    dispatch(getRealtionList("adult"));
  }, []);

  useEffect(() => {
    if (update) {
      dispatch(getUserData(setInputFields));
    }
  }, [update]);

  useEffect(() => {
    // dispatch(updateUserData(inputFields));
    console.log(inputFields);

    if (editable) {
      updateFamilyData();
    }
  }, [data?.customer_family_detail, editable, update]);

  const updateFamilyData = async () => {
    setLoading(true);
    var regex = new RegExp(
      /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
    );

    try {
      const res = await updateUserData(inputFields);
      setUpdate(true);
      toast.success("Added Succesfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setAdult({
        adult: 1,
        child: 0,
      });
      console.log(res);
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(`${t("Something is wrong")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log(error);
      setLoading(false);
    }
  };
  console.log(adult);
  const redirectEdit = (i) => {
    navigate(`/editadult/${i}`);
  };

  const deleteChild = async (p) => {
    setDeleteItem(
      filterData?.filter((item, index) => item?.idx === Number(p))[0]
    );
    const allData = data?.customer_family_detail?.filter((i, k) => {
      if (i.idx !== Number(p)) {
        return i;
      }
    });

    const all = Object.assign(data, { customer_family_detail: allData });
    setLoading1(true);
    try {
      await updateUserData(all);
      toast.error(`${t("Deleted Successfully")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading1(false);
    } catch (error) {
      console.log(error);
      setLoading1(false);
    }
  };

  const handleInputChange = (e) => {

    setAdult((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });


  };
  console.log(adult);
  const handleSubmit = (e) => {
    e.preventDefault();
    let familiyData = inputFields?.customer_family_detail;
    let mydata = familiyData?.concat(adult);
    setInputFields((prev) => {
      return {
        ...prev,
        customer_family_detail: mydata,
      };
    });
    setUpdate(false);
    setEditable(true);
  };
  return (
    <div>
      <Container>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row className={`${Language === "ar" && "flex-row-reverse"}`}>
          <Col md lg="4">
            <div>
              <ProfileSideBar data={data} />
            </div>
          </Col>
          <Col md lg="8">
            <div>
              {!loading ? (
                <div>
                  <h3 className="profile_heading">{t("Add adult member")}</h3>
                  <div>
                    <Form className={Language === "ar" && "addadults"} onSubmit={(e) => handleSubmit(e)}>
                      <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                        <Form.Group
                          className="mb-3 profile_col45"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span> {t("First Name")}{" "}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            required
                            className={`profile_input ${Language === "ar" && "alignright"}`}
                            value={adult?.first_name || ""}
                            type="text"
                            name="first_name"
                            onChange={(e) => handleInputChange(e)}
                            placeholder={t("First Name")}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col45"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span>{t("Last Name")}{" "} </span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            required
                            value={adult?.last_name || ""}
                            className={`profile_input ${Language === "ar" && "alignright"}`}
                            type="text"
                            name="last_name"
                            onChange={(e) => handleInputChange(e)}
                            placeholder={t("Last Name")}
                          />
                        </Form.Group>
                      </div>
                      <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                        <Form.Group
                          className="mb-3 profile_col45"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span> {t("Phone No.")}{" "}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            required
                            defalutValue={adult?.phone_no || ""}
                            name="phone_no"
                            className={`profile_input ${Language === "ar" && "alignright"}`}
                            minLength={10}
                            maxLength={10}
                            min={0}
                            type="number"
                            placeholder={t("Number")}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col45"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span> {t("Email Id")}{" "}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            required
                            value={adult?.email_id || ""}
                            className={`profile_input ${Language === "ar" && "alignright"}`}
                            type="email"
                            name="email_id"
                            onChange={(e) => handleInputChange(e)}
                            placeholder={t("Enter email")}
                          />
                        </Form.Group>
                      </div>

                      <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                        <Form.Group
                          className="mb-3 profile_col30"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span>{t("Date of Birth")}{" "}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            required
                            value={adult?.dob || ""}
                            className="profile_input"
                            type="date"
                            name="dob"
                            max={"9999-12-31"}
                            onChange={(e) => handleInputChange(e)}
                            placeholder={t("DOB")}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col30"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span> {t("Preferred Communication")}{" "} </span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Select
                            required
                            className="profile_input"
                            name="preferred_communication"
                            aria-label="Default select example"
                            value={adult?.preferred_communication || ""}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">
                              {t("Select Preferred Communication")}
                            </option>
                            <option value="Both (SMS or Email)">
                              {t("Both (SMS or Email)")}
                            </option>
                            <option value="Email">{t("Email")}</option>
                            <option value="SMS">{t("SMS")}</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="mb-3 profile_col30"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                            <span>{t("Relation")}{" "}</span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => handleInputChange(e)}
                            className="profile_input"
                            name="relation"
                            value={adult?.relation || ""}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">{t("Select Relation")} </option>
                            {realtion?.map((item, index) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      {/* <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Date Of Birth</Form.Label>
                      <Form.Control
                        type="date"
                        // name="email_id"
                        // defaultValue={data?.email_id}
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Enter email"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Preferred Comunication</Form.Label>
                      <Form.Control
                        type="text"
                        name="preferred_communication"
                        onChange={(e) => handleInputChange(e)}
                        defaultValue={data?.preferred_communication}
                        placeholder="Enter email"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 profile_col30"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Relation</Form.Label>
                      <Form.Control
                        type="text"
                        name="relation"
                        onChange={(e) => handleInputChange(e)}
                        defaultValue={data?.relation}
                        placeholder="Enter email"
                      />
                    </Form.Group>
                  </div> */}
                      <Button
                        className={`profile_button ${Language === "ar" && "d-flex"}`}
                        variant="primary"
                        type="submit"
                      >
                        {t("Add")}
                      </Button>
                    </Form>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
              {!loading1 ? (
                <div>
                  <div>
                    <Table dir={Language === "ar" ? "rtl" : "ltr"} striped>
                      <thead>
                        <tr className="profile_title">
                          <th>{t("sr")}</th>
                          <th>{t("Name")}</th>
                          <th>{t("Email Id")}</th>
                          <th>{t("Phone No")}</th>
                          <th>{t("DOB")}</th>
                          <th>{t("Preferred Communication")}</th>
                          <th>{t("Relation")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterData?.map((item, index) => {
                          return (
                            <tr className="profile_title2">
                              <td>{index + 1}</td>
                              <td>{item?.person_name}</td>
                              <td>{item?.email_id}</td>
                              <td>{item?.phone_no}</td>
                              <td>{item?.dob}</td>
                              <td>{item?.preferred_communication}</td>
                              <td>{item?.relation}</td>
                              {item?.relation !== "self" ? (
                                <td>
                                  <div className="d-flex">
                                    <div
                                      className="me-2"
                                      onClick={() => redirectEdit(item?.idx)}
                                    >
                                      <img src={edit} alt="" />
                                    </div>
                                    <div
                                      variant="danger"
                                      onClick={() => deleteChild(item?.idx)}
                                    >
                                      <img src={delete1} alt="" />
                                    </div>
                                  </div>
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div className={`d-flex py-3 justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <div className={`d-flex  profile_col45 ${Language === "ar" ? "justify-content-start" : "justify-content-end"}`}>
                      <Button
                        onClick={() => {
                          navigate("/myprofile");
                        }}
                        style={{
                          width: "200px",
                        }}
                        className={`profile_button`}
                      >
                        {t("My Profile")}
                      </Button>
                    </div>
                    <div className={`d-flex  profile_col45 ${Language === "ar" ? "justify-content-end" : "justify-content-start"}`}>
                      <Button
                        className="profile_button"
                        onClick={() => {
                          navigate("/addchild");
                        }}
                        style={{
                          width: "200px",
                        }}
                      >
                        {t("Add Your Child")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddAdult;
