const initialState = {
    language: "en",
    
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case "LANGUAGECHNAGEACTION":
            return { ...state, language: action.payload };
        default:
            return state;
    }
};
