import { GET_TIME_SLOT, ORDER_LIST } from "../../constants/actionTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { orderlist: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST:
      return { ...state, orderlist: action.payload };

    default:
      return state;
  }
};
