import * as api from "../../apis";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


export const updateSetPasword =
  (key, password, navigate, setLoading, t) => async (dispatch) => {

    setLoading(true);
    try {
      const { data } = await api.setPassword(key, password);
      console.log(data);
      toast.success(t("Password set successfully"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      navigate("/");
      // setInputFields(data?.message?.customer_data)
      // dispatch({ type: GET_REALTION_LIST, payload: data });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
