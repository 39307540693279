import { PLACE_ORDER, REMOVE_PLACE_ORDER } from "../../constants/actionTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { order: [] }, action) => {
  switch (action.type) {
    case PLACE_ORDER:
      return { ...state, order: action.payload };
    case REMOVE_PLACE_ORDER:
      return {
        order: [],
      };

    default:
      return state;
  }
};
