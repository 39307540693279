import * as api from "../../apis";
import {
  PLACE_ORDER_DETAILS,
  REMOVE_PLACE_ORDER_DETAILS,
} from "../../constants/actionTypes";

export const getPlaceOrderDetails = (name) => async (dispatch) => {
  try {
    const { data } = await api.fetchPlaceOrderDetails(name);

    // setInputFields(data?.message?.customer_data)
    dispatch({ type: PLACE_ORDER_DETAILS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const removeOrderDetails = () => async (dispatch) => {
  try {
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: REMOVE_PLACE_ORDER_DETAILS });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
