// import React, { useEffect, useState } from "react";
// import "./PageThree.css";

// import { Button, Col, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { getTimeSlots } from "../../store/actions/gettimeslotsAction/getTimeSlotAction";
// import {
//   emptyFlow,
//   updateFlow,
// } from "../../store/actions/flowAction/flowAction";
// import { fetchUpdateCart } from "../../store/apis";

// const PageThree = ({ onButtonClick }) => {
//   const dispatch = useDispatch();
//   const data = useSelector((state) => state?.flowReducer?.flow);
//   console.log(data?.uom);
//   const [loading, setLoading] = useState(false);
//   const [selected, setSelected] = useState(data?.slot);
//   const [inputFields, setInputFields] = useState({
//     slot: selected?.length > 0 ? selected : "",
//   });

//   const slots = useSelector(
//     (state) => state?.getTimeSlotReducer?.slot?.message
//   );
//   console.log(data, "redux");
//   console.log(inputFields, "input");

//   const addToCart = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     if (
//       data?.item_code &&
//       data?.qty &&
//       // data?.additional_notes &&
//       data?.branch &&
//       data?.brand &&
//       data?.delivery_date &&
//       // data?.phone &&
//       data?.slot &&
//       data?.uom
//     ) {
//       try {
//         const res = await fetchUpdateCart(
//           data?.item_code,
//           data?.qty,
//           data?.branch,
//           data?.brand,
//           data?.delivery_date,
//           data?.phone,
//           data?.slot,
//           data?.uom
//         );
//         console.log(res);
//         onButtonClick("pagefour");
//         dispatch(emptyFlow());
//         setLoading(false);
//       } catch (error) {
//         console.log(error);
//         alert(error);
//         setLoading(false);
//       }
//     }
//   };

//   const setSlot = (item) => {
//     setSelected(String(item));
//   };
//   console.log(selected?.length);
//   useEffect(() => {
//     if (data?.item_code || data?.delivery_date || data?.branch || data?.uom) {
//       dispatch(
//         getTimeSlots(
//           data?.item_code,
//           data?.delivery_date,
//           data?.branch,
//           data?.uom
//         )
//       );
//     }
//   }, [data]);
//   useEffect(() => {
//     setInputFields({
//       ...inputFields,
//       slot: selected,
//     });
//   }, [selected]);
//   useEffect(() => {
//     if (inputFields?.slot?.length) {
//       const allData = { ...data, ...inputFields };
//       dispatch(updateFlow(allData));
//     }
//   }, [inputFields]);

//   return (
//     <main className="pt5 black-80 center">
//       <div className="">
//         <h2 className="text-center mt-4">Add Addons...</h2>
//         <div>
//           <h3 onClick={() => onButtonClick("pagetwo")}>Back</h3>
//         </div>

//         <Row className="d-flex justify-content-center ">
//           {slots?.map((item, index) => {
//             return (
//               <Col
//                 md
//                 lg="2"
//                 onClick={() => setSlot(item?.Name)}
//                 className={`  d-flex align-items-center justify-content-center me-4 mt-4 mb-4`}
//               >
//                 <Button
//                   className={`${
//                     selected === item?.Name ? "selectedDiv" : null
//                   } pagethree_button`}
//                 >
//                   <span className="pagethree_span">{item?.Name}</span>
//                 </Button>
//               </Col>
//             );
//           })}
//         </Row>
//         <div className="text-center w-100">
//           <Button
//             disabled={loading ? true : false}
//             onClick={(e) => addToCart(e)}
//           >
//             Add to Cart
//           </Button>
//         </div>
//       </div>
//     </main>
//   );
// };

// export default PageThree;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./PageThree.css";

import { Button, Col, Form, Pagination, Row } from "react-bootstrap";
import { redirect, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FoodCard from "../foodcard/FoodCard";
import { useDispatch } from "react-redux";
import calender from "../../assets/images/calender.png";
import price from "../../assets/images/price.png";

import { getCartInfo } from "../../store/actions/cartInfoAction/cartInfoAction";
import { getAddonsList } from "../../store/actions/addonsAction/addonsAction";
import {
    getPlaceOrder,
    removeOrderName,
} from "../../store/actions/placeorderAction/placeOrderAction";
import { removeOrderDetails } from "../../store/actions/placeorderdetailsaction/placeOrderDetailsAction";
import { fetchApplyCouponCode } from "../../store/apis";
import { getApplyCouponCode } from "../../store/actions/applycouponaction/applyCouponCode";
import { useTranslation } from "react-i18next";
import { emptyFlow } from "../../store/actions/flowAction/flowAction";


const PageThree = ({ onButtonClick }) => {
    const { t } = useTranslation(["main"]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [coupon, setCoupon] = useState("");
    const Language = useSelector((state) => state?.languageReducer?.language);
    const [searchInput, setSearchInput] = useState("");
    const [page, setPage] = useState(0);

    const cart = useSelector((state) => state?.cartInfoReducer?.cart?.message);
    console.log(cart);
    const a = [1, 2];

    useEffect(() => {
        dispatch(getCartInfo());
    }, []);
    useEffect(() => {
        if (page >= 0) {
            dispatch(getAddonsList(page, 50, searchInput));
        }
    }, [page, searchInput]);
    useEffect(() => {
        if (update) {
            dispatch(getCartInfo());

            setUpdate(false);
        }
    }, [update]);

    const packageItem = cart?.items?.filter((item, index) => {
        return item?.non_sharable_slot === 1;
    });
    console.log(packageItem);

    const data = useSelector((state) => state?.flowReducer?.flow);

    const addons = useSelector((state) => state?.addonsReducer?.addons);
    console.log(addons);
    const handleSearch = (e) => {
        setSearchInput(e.target.value);
    };

    console.log(data, "flow");
    const handleCode = async (e) => {
        e.preventDefault();
        dispatch(getApplyCouponCode(coupon, t));
    };

    const redirectFive = () => {
        dispatch(removeOrderName());
        dispatch(removeOrderDetails());
        // dispatch(getPlaceOrder());
        dispatch(emptyFlow());
        onButtonClick("pagefour");
    };

    return (
        <main
            className="pt5 black-80 center"
            style={{ maxWidth: "80%", margin: "auto" }}
        >
            <Row>
                <Col md lg="7">
                    <div className="mb-2">
                        <Button
                            className="backButton"
                            style={{ paddingTop: "2px" }}
                            onClick={() => onButtonClick("pagetwo")}
                        >
                            <i className="bi bi-arrow-left"></i> {t("Back")}
                        </Button>
                    </div>
                    <form className="measure mt-5">
                        <h2>{t("Great! Here's the summary of your Party")}</h2>
                        <p style={{ color: "#C0C0C0" }}>
                            {t("You can always create another workspace later")}.
                        </p>
                        <div className="mt-4">
                            {cart?.items?.map((item, index) => {
                                if (item?.non_sharable_slot === 1) {
                                    return (
                                        <div className="d-flex pagefour_orderDiv mt-4">
                                            <div className="pagetwo_imgDiv">
                                                <img
                                                    src={`https://vimtrialtemp.etoserp.com//${item?.image}`}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="ms-4">
                                                <h3>{item.item_name}</h3>
                                                <h3>
                                                    {t("SAR")} {item?.total_amount}
                                                </h3>
                                                <p>
                                                    Includes up to {item.qty}{" "}
                                                    people.
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <div className="mt-4">
                            {cart?.items?.map((item, index) => {
                                if (item?.non_sharable_slot === 0) {
                                    return (
                                        <div className="d-flex pagefour_orderDiv mt-4 mb-4 ">
                                            <div className="pagetwo_imgDiv">
                                                <img
                                                    src={`https://vimtrialtemp.etoserp.com//${item?.image}`}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="ms-4">
                                                <h3>{item.item_name}</h3>
                                                <h3>
                                                    {t("SAR")} {item?.total_amount}
                                                </h3>
                                                <p>
                                                    Includes up to {item.qty}{" "}
                                                    people.
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <div className="mt-4">
                            <h3>{t("Guest(s) included")}:{cart?.items[0]?.qty}</h3>
                        </div>

                        {/* <div className="">
              <Button
                type="submit"
                value="Create Workspace"
                onClick={() => onButtonClick("pagethree")}
              >
                Go to next page
              </Button>
            </div> */}
                    </form>
                </Col>
                <Col md lg="5">
                    <div className="mt-4 pagefour_summary">
                        <h3>{t("Party Summary")}</h3>
                        <h5 className="my-4">{t("Customer Name")}: <span>{cart?.customer_from_website ? cart?.customer_from_website : cart?.customer_name}</span>  </h5>
                        <div className="d-flex align-items-center">
                            <div className="pagefour_summaryImg">
                                <img src={calender} alt="" />
                            </div>
                            <div className="d-flex mt-2">
                                <p className="pagefour_summaryText">
                                    {" "}
                                    {cart?.transaction_date}
                                </p>
                            </div>
                        </div>
                        {/* <h4>Package : SAR {packageItem?.item?.total_amount} </h4> */}

                        {cart?.items?.map((item, index) => {
                            return (
                                <div className="d-flex justify-content-between">
                                    <p className="pagefour_summaryText">
                                        {item?.item_name}({item?.qty})
                                    </p>

                                    <p className="pagefour_summaryText">
                                        {item?.total_amount}
                                    </p>
                                </div>
                            );
                        })}
                        <hr />
                        <div className="">
                            <div className={`d-flex justify-content-between  ${Language === "ar" && "flex-row-reverse"}`}>
                                <p className=" pagefour_summaryText">
                                    {`${Language === "ar" ? ":" : ""}`} {t("Taxes and Charges")} {`${Language === "ar" ? "" : ":"}`}
                                </p>
                                <p className=" pagefour_summaryText">
                                    {t("SAR")} {cart?.taxes_and_charges}
                                </p>
                            </div>
                            <div className={`d-flex justify-content-between  ${Language === "ar" && "flex-row-reverse"}`}>
                                <p className=" pagefour_summaryText">
                                    {`${Language === "ar" ? ":" : ""}`} {t("Subtotal")} {`${Language === "ar" ? "" : ":"}`}
                                </p>
                                <p className={`pagefour_summaryText ${Language === "ar" && "flexReverse"}`}>
                                    <span> {t("SAR")}</span> <span> {cart?.net_total}</span>
                                </p>
                            </div>
                            <div className={`d-flex justify-content-between  ${Language === "ar" && "flex-row-reverse"}`}>
                                <p className="pagefour_summaryText">
                                    {`${Language === "ar" ? ":" : ""}`} {t("Sales Tax")} {`${Language === "ar" ? "" : ":"}`}
                                </p>
                                <p className={`pagefour_summaryText ${Language === "ar" && "flexReverse"}`}>
                                    <span> {t("SAR")} </span> <span> {cart?.total_taxes_and_charges}</span>
                                </p>
                            </div>
                            <div className={`d-flex justify-content-between  ${Language === "ar" && "flex-row-reverse"}`}>
                                <p className=" pagefour_summaryText d-flex">
                                    <span className="pagefour_summaryImg">
                                        <img src={price} alt="" />
                                    </span>
                                    {`${Language === "ar" ? ":" : ""}`}  {t("Total Due")} {`${Language === "ar" ? "" : ":"}`}
                                </p>
                                <p className={`pagefour_summaryText ${Language === "ar" && "flexReverse"}`}>
                                    <span>{t("SAR")} </span> <span> {cart?.total}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Form
                            className={`d-flex ${Language === "ar" && "flex-row-reverse justify-content-end"}`}
                            onSubmit={(e) => handleCode(e)}
                        >
                            <Form.Control
                                defaultValue={coupon}
                                className="foodcard_input"
                                type="text"
                                name="code"
                                onChange={(e) => setCoupon(e.target.value)}
                                placeholder={t("Coupon Code")}
                            />
                            <Button type="submit">{t("Apply")}</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
            <div className="mt-4 profile_title">
                <h2>
                    {t("Make your own party even better with the additional items below")}
                </h2>
            </div>
            <div className="d-flex justify-content-end">
                <Form>
                    <Form.Control
                        defaultValue={searchInput}
                        className="foodcard_input"
                        type="text"
                        name="search"
                        onChange={(e) => handleSearch(e)}
                        placeholder={t("Addons")}
                    />
                </Form>
            </div>

            <div>
                <h2 className="text-center">{t("Add Addons")}...</h2>
                <Row className="">
                    {addons?.length > 0 &&
                        addons?.map((item, index) => {
                            return (
                                <FoodCard
                                    data={item}
                                    setUpdate={setUpdate}
                                    setLoading={setLoading}
                                />
                            );
                        })}
                </Row>

                <div className="text-center w-100">
                    <Button
                        className="backButton"
                        onClick={(e) => redirectFive(e)}
                    >
                        {t("Add to Cart")}
                    </Button>
                </div>
                <div className="d-flex justify-content-end">
                    <Pagination>
                        <Pagination.First onClick={() => setPage(0)} />
                        <Pagination.Prev
                            disabled={page === 0}
                            onClick={() =>
                                setPage((prev) => prev !== 0 && prev - 1)
                            }
                        />

                        {[...Array(10)]?.map((pa, index) => (
                            <Pagination.Item
                                key={index}
                                onClick={() => setPage(index)}
                                active={index === page}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => setPage((prev) => prev + 1)}
                        />
                        <Pagination.Last onClick={() => setPage(10)} />
                    </Pagination>
                </div>
            </div>
        </main>
    );
};

export default PageThree;
