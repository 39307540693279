import React, { useEffect, useLayoutEffect, useState } from "react";
import MultiStepProgress from "../../components/multistepprogressbar/MultiStepProgress";
import PageFive from "../../components/pagefive/PageFive";
import PageFour from "../../components/pagefour/PageFour";
import PageOne from "../../components/pageone/PageOne";
import PageThree from "../../components/pagethree/PageThree";
import PageTwo from "../../components/pagetwo/PageTwo";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { submitOrder } from "../../store/apis";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const ProgressPage = () => {
    const { t } = useTranslation(["main"]);
    const [page, setPage] = useState("pageone");
    const [loading, setLoading] = useState(false);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const navigate = useNavigate();

    // useEffect runs asynchronously but useLayoutEffect runs synchdonouslr before DOM is painted but slow performance.
    // Using this flicker can be stopped as shown below
    useLayoutEffect(() => {  
        if (urlParams.get("checkoutId")) {
            setLoading(true);
            setTimeout(() => {
                checkPayment_SubmitOrder();
            }, 500);
        }
    }, [])

    const checkPayment_SubmitOrder = async () => {
        const checkoutId = localStorage.getItem('checkoutId')
        const url = `https://eu-test.oppwa.com/v1/checkouts/${checkoutId}/payment`
        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer OGFjN2E0Yzk3ZTdjOTZkODAxN2U4NWRmOWY1MzExN2V8ZTlHNGQ1UUdTcA=="
        }
        const payload = new URLSearchParams();
        payload.append('entityId', '8ac7a4c97e7c96d8017e85e00f701182');

        try {
            const response = await axios.get(url, payload, { headers });
            if (response.data?.result?.code === "000.100.112" && response.data?.amount) {
                const res = await submitOrder(response.data?.amount, "paid", response.data.id);
                toast.success(`${t("Your Order has been booked.")}`, {
                    position: "top-center",
                    autoClose: 0,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                navigate("/salesinvoice");
            }
            else {
                setPage("pagefour");
                toast.error(`${t("Payment not successfull. Please try again.")}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log("Payment Error response", response);
            }
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false);
        }

        return null
    }

    const nextPage = (page) => {
        setPage(page);
    };

    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
            case "1":
                setPage("pageone");
                break;
            case "2":
                setPage("pagetwo");
                break;
            case "3":
                setPage("pagethree");
                break;
            case "4":
                alert(`${t("Ooops! Seems like you did not fill the form.")}`);
                break;
            default:
                setPage("1");
        }
    };
    return (
        <>
            <div hidden={!loading}>
                <h1 style={{textAlign: "center", color: "dimgray", margin:"200px 0"}}>
                     Processing payment! Please be patient. . . .</h1>
            </div>
            <div hidden={loading}>
                <MultiStepProgress page={page} onPageNumberClick={nextPageNumber} />
                {
                    {
                        pageone: <PageOne onButtonClick={nextPage} />,
                        pagetwo: <PageTwo onButtonClick={nextPage} />,
                        pagethree: <PageThree onButtonClick={nextPage} />,
                        pagefour: <PageFour onButtonClick={nextPage} />,
                        // pagefive: <PageFive onButtonClick={nextPage} />,
                    }[page]
                }
            </div>
        </>
    );
};

export default ProgressPage;
