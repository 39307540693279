import * as api from "../../apis";
import { GET_BRANCH_LIST } from "../../constants/actionTypes";

export const getBranchList = (brand) => async (dispatch) => {
  try {
    const { data } = await api.fetchBranchList(brand);
    console.log(data);

    dispatch({ type: GET_BRANCH_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
