import React from "react";
import { Col, Row } from "react-bootstrap";

const ThankYou = () => {
  return (
    <div>
      <Row>
        <Col lg={12} className="thank-you-desc">
          <h3>
            Register <br /> For Aspire Home Fest 2023
          </h3>
          <div className="thank-you-message">
            <p>Feel free to reach out to us for any queries. </p>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ flexDirection: "column" }}
            >
              <span className="text-white m-1">Call : 98866 60229</span>
              <span className="text-white m-1">
                Email : hello@aspireprop.com
              </span>
            </div>
          </div>
          <div className="pass-download-btn mt-4">
            <button> Aspire Home Fest</button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ThankYou;
