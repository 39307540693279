import { GET_CITY, USER } from "../../constants/actionTypes";

// const initialState = {
//   user: [],
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { city: [] }, action) => {
  switch (action.type) {
    case GET_CITY:
      return { ...state, city: action.payload };

    // case SIGNUP:
    //   break;

    default:
      return state;
  }
};
