import React from "react";
import { Col, Row } from "react-bootstrap";
import PartyCard from "./PartyCard";
import offer4 from "../../assets/images/offer4.jpg";
import "./PartyBooking.css";

const PartyBooking = ({ data }) => {
  return (
    <div className="partyBooking_partyDiv mt-4 ">
      {/* <div className="text-center partyBooking_title">
        <h2>Party Booking</h2>
      </div> */}
      <Row className="">
        {data?.map((item, index) => {
          return <PartyCard item={item} />;
        })}
      </Row>

      {/* <Row className="d-flex justify-content-between">
        <Col className="partyBooking_offerImg" md lg="5">
          <img src={offer4} alt="" />
        </Col>
        <Col className="partyBooking_offerImg" md lg="5">
          <img src={offer4} alt="" />
        </Col>
      </Row> */}
    </div>
  );
};

export default PartyBooking;
