import React from "react";
import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  let sid;

  sid = Cookies.get("sid");
  return sid ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
