import React from "react";
import "./PageFour.css";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getPlaceOrderDetails } from "../../store/actions/placeorderdetailsaction/placeOrderDetailsAction";
import { useState } from "react";
import { placeOrder, submitOrder } from "../../store/apis";
import { getCartInfo } from "../../store/actions/cartInfoAction/cartInfoAction";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Helmet } from "react-helmet";
import mada from "../../assets/images/Mada_Logo.svg.png";
import visa from "../../assets/images/visa.png";
import mastercard from "../../assets/images/master.png";

const PageFour = ({ onButtonClick }) => {
  const { t } = useTranslation(["main"]);
  const Language = useSelector((state) => state?.languageReducer?.language);
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const data = useSelector((state) => state?.flowReducer?.flow);
  const [selected, setSelected] = useState();
  const [card_type, setcard_type] = useState("");
  // const order1 = useSelector(
  //   (state) => state?.placeOrderReducer?.order?.message
  // );
  const cart = useSelector((state) => state?.cartInfoReducer?.cart?.message);
  console.log(cart);

  const orderDetails = useSelector(
    (state) => state?.placeOrderDetailsReducer?.orderDetails.message
  );
  console.log(orderDetails);

  const dispatch = useDispatch();

  const handleValue = (value, id) => {
    setValue(value);
    setSelected(id);
  };
  const handleSubmit = async () => {
    if (value) {
      try {
        const res = submitOrder(value);
        navigate(`/salesinvoice`);
        alert(`${t("Your Order has been booked")}`);
      } catch (error) {
        alert(error);
      }
    } else {
      console.log("close")
      alert(`${t("please select the amount")}`);
    }
  };

  useEffect(() => {
    dispatch(getCartInfo());
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const btnclick_visa = () => {
    generateCheckoutId("VISA MASTER");
    setcard_type("VISA MASTER");
  }

  const btnclick_mada = () => {
    generateCheckoutId("MADA");
    setcard_type("MADA");
  }

  const [checkoutId, setcheckoutId] = useState(null);
  const generateCheckoutId = async ($cardType) => {
    const url = "https://eu-test.oppwa.com/v1/checkouts"
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer OGFjN2E0Yzk3ZTdjOTZkODAxN2U4NWRmOWY1MzExN2V8ZTlHNGQ1UUdTcA=="
    }

    const _entityId = ($cardType === "MADA") ? "8ac7a4c97e7c96d8017e85e0c63c1187" : "8ac7a4c97e7c96d8017e85e00f701182";

    const payload = new URLSearchParams();
    payload.append('entityId', _entityId);
    payload.append('amount', value);
    payload.append('currency', 'SAR');
    payload.append('paymentType', 'DB');
    payload.append('testMode', 'EXTERNAL');
    payload.append('merchantTransactionId', cart.name);
    payload.append('customer.givenName', 'Anoop');
    payload.append('customer.surname', 'Nair');
    payload.append('billing.street1', 'A/2, Nehru Nagar');
    payload.append('billing.city', 'Mumbai');
    payload.append('billing.state', 'Maha');
    payload.append('billing.country', 'IN');
    payload.append('billing.postcode', '400042');

    try {
      const response = await axios.post(url, payload, { headers });
      console.log(response)
      localStorage.setItem('checkoutId', response?.data?.id)
      setcheckoutId(response?.data?.id)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <main
        className="py-5 black-80 center"
        style={{ maxWidth: "60%", maxHeight: "30%", margin: "auto" }}
      >
        {/* <div>
          <h3 onClick={() => onButtonClick("pagefour")}>Back</h3>
        </div> */}
        <div className="text-center mainDiv">
          <h3>{t("Almost done! Review your order and checkout")} </h3>
          <Container>
            <div className="">
              <div>
                <h3>{cart?.name}</h3>
                <div className="row py-2">
                  <div className="col-6">
                    <div className="text-start">
                      <h3>{t("Customer Name")}: {cart?.customer_from_website ? cart?.customer_from_website : cart?.customer_name}</h3>
                    </div>
                    {/* <div className="subContainDiv text-start py-2">
                      <h3>{t("Address")}</h3>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: cart?.address_display,
                        }}
                      ></h3>
                    </div> */}
                  </div>
                  <div className="text-end col-6">
                    <h3>{t("Event Date")}: {cart?.items?.length > 0 && cart?.items[0]?.delivery_date}</h3>
                  </div>
                </div>
                <hr />
              </div>
              <div>
                <h3>{cart?.select_event}</h3>
                <h3>{t("Slot")}: {cart?.select_slot}</h3>
                <div className="text-start p-2">
                  <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                    <h3>{t("PARTY PACKAGE")}</h3>
                    <h3>{t("Price")}</h3>
                  </div>

                  {cart?.items.map((item, index) => {
                    return (
                      <div className={`d-flex justify-content-between ${Language === "ar" && "flex-row-reverse"}`}>
                        <h3>
                          {item?.item_name}({item?.qty})
                        </h3>
                        <h3>{item?.total_amount}</h3>
                      </div>
                    );
                  })}
                </div>
                <hr />
              </div>
              <div className="pageFive_guestDiv text-left py-4">
                <h3>{t("Guests")} </h3>
                <h3>{orderDetails?.no_of_entries} included guests</h3>
              </div>

              <div className="mt-4 mb-4 pageFive_priceDiv">
                <div className={`d-flex justify-content-between mt-4 ${Language === "ar" && "flex-row-reverse"}`}>
                  <h3>{t("Subtotal")}</h3>
                  <h3 className={`${Language === "ar" && "flexReverse"}`}> <span> {t("SAR")}</span> <span> {cart?.net_total}</span></h3>
                </div>
                <div className={`d-flex justify-content-between mt-4 ${Language === "ar" && "flex-row-reverse"}`}>
                  <h3>{t("Taxes and Charges")}</h3>
                  <h3 className={`${Language === "ar" && "flexReverse"}`}><span> {t("SAR")}</span> <span> {cart?.total_taxes_and_charges}</span></h3>
                </div>
                <div className={`d-flex justify-content-between mt-4 ${Language === "ar" && "flex-row-reverse"}`}>
                  <h3>{t("Total")}</h3>
                  <h3 className={`${Language === "ar" && "flexReverse"}`}><span>{t("SAR")}</span> <span>{cart?.total}</span></h3>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="text-center mt-4">
          <h2>{t("Choose a payment option")}</h2>
          <div className="d-flex justify-content-around ">
            <div
              onClick={() => handleValue(cart?.total, "1")}
              className={`pageFive_paymentButton  ${selected === "1" && "pageFive_selected"
                }`}
            >
              <h3> {t("Total Due")}</h3>
              <h3> {t("SAR")} {cart?.total}</h3>
            </div>
            {cart?.total > 1000 && (
              <div
                onClick={() => handleValue(1000, "2")}
                className={`pageFive_paymentButton  ${selected === "2" && "pageFive_selected"
                  }`}
              >
                <h3>{t("Minimum Deposit")}</h3>
                <h3> {t("SAR")} 1000</h3>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex gap-5 justify-content-center">
          <div className="text-center my-4 ">
            <Button className="btnPayments" onClick={() => handleSubmit()} disabled={!!value ? false : true}>
              {t("Pay Later")}
            </Button>
          </div>
          <div className="text-center my-4 ">
            <Button className="btnPayments" onClick={handleShow} disabled={!!value ? false : true}>
              {t("Checkout")}
            </Button>
          </div>
        </div>
      </main>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Process</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-2 justify-content-center payBtnHolder">
            <Button onClick={btnclick_visa}>
              <span>
                VISA / MASTERCARD
              </span>
              <div>
                <img src={mastercard} alt="mastercard" />
                <img src={visa} alt="visa" />
              </div>
            </Button>
            <Button onClick={btnclick_mada}>
              <span>
                MADA
              </span>
              <img src={mada} alt="mada" />
            </Button>
          </div>
          <div id="cardContainer" key={card_type}>
            <form action={`/progresspage?checkoutId=${checkoutId}`} className="paymentWidgets" data-brands={card_type}></form>
          </div>
        </Modal.Body>
      </Modal>
      <Helmet>
        <script src={`https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`}></script>
      </Helmet>
    </div>
  );
};

export default PageFour;
