import * as api from "../../apis";
import { GET_CITY, LOGIN, SIGNUP, USER } from "../../constants/actionTypes";
import Cookies from "js-cookie";

export const getCityList = () => async (dispatch) => {
  try {
    const { data } = await api.fetchCityList();
    console.log(data);
    // setDefaultCity(data?.message[0]);
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: GET_CITY, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
