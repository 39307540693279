import "./App.css";
import { Header } from "./components/header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { Footer } from "./components/footer/Footer";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";

import { HomePage } from "./screens/homepage/HomePage";
import { AboutUs } from "./screens/aboutus/AboutUs";
import Login from "./screens/login/Login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import Profile from "./screens/profile/Profile";
import AddChild from "./screens/addchild/AddChild";
import AddAdult from "./screens/addadult/AddAdult";
import Signup from "./screens/signup/Signup";
import EditChild from "./screens/editchild/EditChild";
import EditAdult from "./screens/editadult/EditAdult";
import ChangePassword from "./screens/changepassword/ChangePassword";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import SetPassoword from "./screens/setpassword/SetPassoword";
import Cookies from "js-cookie";
import { Suspense, useEffect } from "react";
import PartyBooking from "./components/partybooking/PartyBooking";
import PartyBookingPage from "./screens/partybookingpage/PartyBookingPage";
import { CardDetail } from "./screens/carddetail/CardDetail";
import ProgressPage from "./screens/progresspage/ProgressPage";
import ForgotPassword from "./screens/forgotpassword/ForgotPassword";
import SalesInvoice from "./screens/salesinvoice/SalesInvoice";
import SalesDetails from "./screens/salesdetails/SalesDetails";
import ThankYou from "./components/thankyou/ThankYou";
import { useSelector } from "react-redux";
import * as api from './store/apis'

const Redirect = () => {
    let sid;
    const navigate = useNavigate();
    sid = Cookies.get("sid");
    const location = useLocation();
    useEffect(() => {
        api.fetchLoggedUser().then((data)=>{
            if(data.data.message !== "Guest"){
                Cookies.set("sid", data.data.message);
                sid = data.data.message;
            }
            else{
                Cookies.remove("sid");
                sid = null;
            }

            if (location.pathname === "/" && sid) {
                navigate("myprofile");
            }
            else if(location.pathname === "/partybooking" && sid){
                navigate(location.pathname);
            }
        })
    }, []);
    return null;
};
function App() {
    const Language = useSelector((state) => state?.languageReducer?.language);
    return (
        <Suspense fallback={null}>
            <div className={Language === "ar" && "App"}>
                <Router>
                    <Redirect />
                    <Header />
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                    <Routes>
                        <Route element={<ProtectedRoutes />}>
                            <Route
                                path="/editchild/:id"
                                element={<EditChild />}
                            />
                            <Route
                                path="/editAdult/:id"
                                element={<EditAdult />}
                            />
                            <Route path="/myprofile" element={<Profile />} />
                            <Route path="/thank-you" element={<ThankYou />} />
                            <Route path="/addchild" element={<AddChild />} />
                            <Route path="/addadult" element={<AddAdult />} />
                            <Route
                                path="/partybooking"
                                element={<PartyBookingPage />}
                            />
                            <Route
                                path="/carddetail/:id"
                                element={<CardDetail />}
                            />
                            <Route
                                path="/progresspage"
                                element={<ProgressPage />}
                            />
                            <Route
                                path="/salesinvoice"
                                element={<SalesInvoice />}
                            />
                            <Route
                                path="/salesdetails/:id"
                                element={<SalesDetails />}
                            />

                            <Route
                                path="/changepassword"
                                element={<ChangePassword />}
                            />
                        </Route>
                        {/* <Route path="/" element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUs />} /> */}
                        <Route path="/" element={<Login />} />
                        <Route
                            path="/forgotpassword"
                            element={<ForgotPassword />}
                        />
                        <Route path="/setpassword" element={<SetPassoword />} />
                        <Route path="/signup" element={<Signup />} />
                    </Routes>
                </Router>
                <Footer />
            </div>
        </Suspense>
    );
}

export default App;
