import * as api from "../../apis";
import { GET_CHARACTER_LIST } from "../../constants/actionTypes";

export const getCharacterList = (setInputFields) => async (dispatch) => {
  try {
    const { data } = await api.fetchCharacterList();
    // setInputFields(data?.message?.customer_data)
    dispatch({ type: GET_CHARACTER_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const signUpRequest = (formData) => async (dispatch) => {
//   try {
//   } catch (error) {}
// };
