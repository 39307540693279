import React from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrderList } from "../../store/actions/orderlistAction/orderlistAction";
import "./SalesInvoice.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { submitOrder } from "../../store/apis";
const SalesInvoice = () => {

    const Language = useSelector((state) => state?.languageReducer?.language);
    const { t } = useTranslation(["main"]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOrderList());
    }, []);

    // const handleSubmit = async () => {
    //     if (value) {
    //       try {
    //         navigate(`/salesinvoice`);
    //       } catch (error) {
    //         alert(error);
    //       }
    //     } else {
    //       console.log("close")
    //       alert(`${t("please select the amount")}`);
    //     }
    //   };
    const navigate = useNavigate();

    const orderList = useSelector(
        (state) => state?.orderlistReducer?.orderlist?.message
    );

    const redirect = (id) => {
        navigate(`/salesdetails/${id}`);
    };
    return (
        <div className="container">
            <div className="salesmainDiv">
                <Table
                    size="md"
                    style={{ fontSize: "12px" }}
                    striped
                    bordered
                    hover
                    dir={Language === "ar" ? "rtl" : "ltr"}
                >
                    <thead>
                        <tr>
                            <th>{t("SL.No")}</th>
                            <th>{t("Order No")}</th>
                            <th>{t("Item Name")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Event Date")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderList?.map((item, index) => {
                            return (
                                <tr onClick={() => redirect(item?.name)}>
                                    <td>{index + 1}</td>
                                    <td style={{ cursor: "pointer" }}>
                                        {item?.name}
                                    </td>
                                    <td>
                                        {item?.items
                                            ?.map((i) => i.item_name)
                                            ?.join(", ")}
                                    </td>
                                    <td>{item?.base_total}</td>
                                    <td>{item?.delivery_date}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default SalesInvoice;
