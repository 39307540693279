import React, { useEffect, useState } from "react";
import "./PageTwo.css";
import Funtazia from "../../assets/images/Funtazia.png";
import offer4 from "../../assets/images/offer4.jpg";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FoodCard from "../foodcard/FoodCard";
import {
    emptyFlow,
    updateFlow,
} from "../../store/actions/flowAction/flowAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBrandList } from "../../store/actions/brandlistAction/brandListActtion";
import { getBranchList } from "../../store/actions/branchAction/branchAction";
import { fetchIsSlot, fetchUpdateCart } from "../../store/apis";
import { getTimeSlots } from "../../store/actions/gettimeslotsAction/getTimeSlotAction";
import { useTranslation } from "react-i18next";

const PageTwo = ({ onButtonClick }) => {
    const Language = useSelector((state) => state?.languageReducer?.language);
    const { t } = useTranslation(["main"]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const info = useSelector((state) => state?.ItemInfoReducer?.info?.message);

    const data = useSelector((state) => state?.flowReducer?.flow);
    const [selected, setSelected] = useState(data?.slot);

    const slots = useSelector(
        (state) => state?.getTimeSlotReducer?.slot?.message
    );
    console.log(data, "flow");
    const [available, setAvailable] = useState("");
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState({
        uom: data?.uom ? data?.uom : "",
        delivery_date: data?.delivery_date ? data?.delivery_date : "",
        slot: data?.slot?.length > 0 ? data?.slot : "",
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        const allData = { ...data, ...inputFields };

        dispatch(updateFlow(allData));

        // e.preventDefault();
        setLoading(true);
        if (
            data?.item_code &&
            data?.qty &&
            // data?.additional_notes &&
            data?.branch &&
            data?.brand &&
            data?.delivery_date &&
            // data?.phone &&
            data?.slot &&
            data?.uom
        ) {
            try {
                const res = await fetchUpdateCart(
                    data?.item_code,
                    data?.qty,
                    data?.branch,
                    data?.brand,
                    data?.delivery_date,
                    data?.phone,
                    data?.slot,
                    data?.uom,
                    data.customer_name,
                );
               
                
                onButtonClick("pagethree");
                // dispatch(emptyFlow());
                setLoading(false);
            } catch (error) {
                console.log(error);
                alert(error);
                setLoading(false);
            }
        }
    };

    const handleInputChange = (e) => {
        console.log(e.target.name, e.target.value);
        setInputFields((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };
    console.log(inputFields);
    useEffect(() => {
        if (inputFields?.uom || inputFields?.delivery_date) {
            const allData = { ...data, ...inputFields };

            dispatch(updateFlow(allData));
        }
        if (inputFields?.slot?.length) {
            const allData = { ...data, ...inputFields };
            dispatch(updateFlow(allData));
        }
    }, [inputFields]);
    console.log(available);
    useEffect(() => {
        if (available && update) {
            if (
                data?.item_code ||
                data?.delivery_date ||
                data?.branch ||
                data?.uom
            ) {
                dispatch(
                    getTimeSlots(
                        data?.item_code,
                        data?.delivery_date,
                        data?.branch,
                        data?.uom
                    )
                );
                setUpdate(false);
            }
        }
    }, [available, update]);

    // useEffect(() => {
    //   setInputFields({
    //     ...inputFields,
    //     slot: selected,
    //   });
    // }, [selected]);

    useEffect(() => {
        if (data?.item_code || data?.delivery_date || data?.uom) {
            isSlotAvailible();
        }
    }, [data?.item_code, data?.delivery_date, data?.uom]);

    const isSlotAvailible = async () => {
        try {
            const res = await fetchIsSlot(
                data?.item_code,
                data?.delivery_date,
                data?.branch
            );
            console.log(res?.data?.message?.available);
            if (res?.data?.message?.available) {
                setUpdate(true);
            }
            setAvailable(res?.data?.message?.available);
        } catch (error) {
            console.log(error);
        }
    };
    console.log(data, "redux");
    return (
        <main
            className="pt5 black-80 center"
            style={{ maxWidth: "40%", maxHeight: "30%", margin: "auto" }}
        >
            <div className={`${Language === 'ar' && "d-flex"}`}>
                <Button
                    className="backButton"
                    style={{ paddingTop: "2px" }}
                    onClick={() => onButtonClick("pageone")}
                >
                    <i className="bi bi-arrow-left"></i>
                    {t("Back")}
                </Button>
            </div>
            <Form className={`${Language === 'ar' && "text-end"}`} onSubmit={(e) => handleSubmit(e)}>
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span>{t("Event Date")}{" "}</span>
                        <span
                            style={{
                                color: "red",
                                fontSize: "1.2rem",
                            }}
                        >
                            *
                        </span>
                    </Form.Label>
                    <Form.Control
                        required
                        className="profile_input"
                        type="date"
                        max={"9999-12-31"}
                        name="delivery_date"
                        defaultValue={data?.delivery_date || ""}
                        onChange={(e) => handleInputChange(e)}
                        placeholder={t("Enter date of birth")}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3 profile_col40"
                    controlId="formBasicEmail"
                >
                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span> {t("Slot Type")}{" "}</span>
                        <span
                            style={{
                                color: "red",
                                fontSize: "1.2rem",
                            }}
                        >
                            *
                        </span>
                    </Form.Label>
                    <Form.Select
                        onChange={(e) => handleInputChange(e)}
                        className="profile_input"
                        name="uom"
                        value={data?.uom || ""}
                        aria-label="Default select example"
                        required
                    >
                        <option value="">{t("Select Time")} </option>
                        {info?.uom_list?.map((item, index) => {
                            return <option value={item}>{item} </option>;
                        })}
                    </Form.Select>
                </Form.Group>

                <Form.Group
                    className="mb-3 profile_col40"
                    controlId="formBasicEmail"
                >
                    <Form.Label className={`profile_title ${Language === "ar" && "d-flex flex-row-reverse"}`}>
                        <span> {t("Time Slot")}{" "}</span>
                        <span
                            style={{
                                color: "red",
                                fontSize: "1.2rem",
                            }}
                        >
                            *
                        </span>
                    </Form.Label>
                    <Form.Select
                        // disabled={data?.brand?.length > 0 ? false : true}
                        onChange={(e) => handleInputChange(e)}
                        className="profile_input"
                        name="slot"
                        aria-label="Default select example"
                        required
                        value={data?.slot || ""}
                    >
                        <option value="">{t("Select Slot")}</option>
                        {slots?.map((item, index) => {
                            return (
                                <option value={item?.id}>{item?.Name}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group
                    className="mb-3 d-flex flex-column"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="profile_title">
                        {t("Additional Remarks")}
                    </Form.Label>
                    <textarea
                        className={`profile_input ${Language === 'ar' && "text-end"}`}
                        type="text"
                        defaultValue={data?.additional_notes || ""}
                        name="additional_notes"
                        onChange={(e) => handleInputChange(e)}
                        placeholder={t("Additional Remarks")}
                    />
                </Form.Group>
                {/* <Form.Group className="mb-3 profile_col40" controlId="formBasicEmail">
          <Form.Label className="profile_title">
            Time Slot{" "}
            <span
              style={{
                color: "red",
                fontSize: "1.2rem",
              }}
            >
              *
            </span>
          </Form.Label>
          <Form.Select
            // onChange={(e) => handleInputChange(e)}
            className="profile_input"
            name="relation"
            // value={adult?.relation || ""}
            aria-label="Default select example"
            required
          >
            <option value="">Select Relation </option>
            <option value="">Select Relation </option>
            <option value="">Select Relation </option>
          </Form.Select>
        </Form.Group> */}
                <div className={`${Language === 'ar' ? "text-center mt-4 mb-4 d-flex " : "text-center mt-4 mb-4"}`}>
                    <Button
                        // disabled={available ? false : true}
                        className="backButton"
                        type="submit"
                        value="Create Workspace"
                    >
                        {t("Add to Cart")}
                    </Button>
                </div>
            </Form>
        </main>
    );
};

export default PageTwo;
