import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import "bootstrap-icons/font/bootstrap-icons.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../src/store";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>

);
