import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PartyBooking from "../../components/partybooking/PartyBooking";
import { getItemList } from "../../store/actions/itemlistAction/ItemListAction";
import "./PartyBookingPage.css";
import { useTranslation } from "react-i18next";

const PartyBookingPage = () => {
  const { t } = useTranslation(["main"]);
  const dispatch = useDispatch();

  const items = useSelector((state) => state?.itemlistReducer?.item?.message);
  console.log(items);

  const a = [];

  useEffect(() => {
    dispatch(getItemList(0, 6));
  }, []);

  const getText = (e) => {
    console.log(e.target.name);
  };
  return (
    <Container className="mt-4">
      <Row className="d-flex justify-content-between">
        {/* <Col md lg="3">
          <div className="mt-4">
            <div className="d-flex justify-content-between">
              <h4 className="partybooking_title">{t("Filters")}</h4>
              <h4 className="partybooking_clear">{t("Clear All")}</h4>
            </div>
            <p className="partybooking_text">{t("Item Group")}</p>
            <div>
              <Form>
                <Form.Control
                  type="text"
                  className="profile_input"
                  // onChange={(e) => setSearchText(e.target.value)}
                  placeholder={t("Search")}
                />
                <div className="search_div mt-4 mb-4">
                  {a.map((item, index) => {
                    return (
                      <div key={index} className="mb-2 pe-4 d-flex align-items-center checkbox_text">
                        <Form.Check
                          className="checkbox_text me-2"
                          type="checkbox"
                          onChange={getText}
                          name="bakery"
                        // id={`default-${type}`}
                        />
                        <Form.Label>{t("bakery")}</Form.Label>
                      </div>
                    );
                  })}
                </div>
              </Form>
            </div>
          </div>
        </Col> */}
        <Col md lg={{span: 9, offset:2}}>
          <div className="mb-4 mt-4">
            <PartyBooking data={items} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PartyBookingPage;
